#batting_summary_white_bg {
  background-color: #ffffff;
  width: 58.75vw;
  height: 79vh;
  z-index: 2;
  font-family: "Oswald";
}
#batting_summary_teama_name_bg {
  background-color: #bffe01;
  width: 49.2vw;
  height: 9.5vh;
  position: absolute;
  left: -5.2vw;
  z-index: 1;
}

#batting_summary_logo_outer_gradient {
  width: 7.8vw;
  height: 15.6vh;
  transform: rotate(50deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  left: -4.6vw;
}
#batting_summary_logo_inner_gradient {
  width: 5.8vw;
  height: 11.6vh;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#summary_team_logo {
  transform: rotate(-50deg);
  width: 3.5vw;
  height: 7vh;
}
#batting_team_text {
  font-size: 2.2vw;
  color: #001030;
  font-weight: 600;
  line-height: 5.4vh;
  /* padding-left: 1.4vw; */
  position: absolute;
  left:6.5vw;
  text-transform: uppercase;
}
#batting_summary_text {
  padding-right: 1vw;
  padding-bottom: 1vh;
  text-transform: uppercase;
  font-size: 1vw;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: end;
}

#teams_content {
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-bottom: 1vh;
  margin-top: 5vh;
  /* padding:1vh; */
}
#non_batting_teams_content{
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  /* margin-bottom: 1vh; */
  /* margin-top: 5vh; */
}
/* #player_row {
  margin-bottom: 1vh;
} */
/* #batting_teams{
  height: 600px;
} */
#batting_summary_player_name_bg {
  height: 5vh;
  background-color: #0b1234;
  width: 48.125vw!important;
}
#batting_summary_batsman_text {
  padding-left: 6vh;
  display: flex;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2vh;
  text-transform: uppercase;
  text-align: left;
  /* width: 11.6vw;
  text-overflow: ellipsis;
  height: 3vh;
  white-space: nowrap;
  overflow: hidden; */
}
#non_batting_players{
  height: 5vh;
  width: 48vw;
  text-align: center;
  background-color: white;
  color: #001030;
  padding-top: 1.3vh;
  margin-top: 0.8vh;
}
#batting_summary_catcher_text {
  font-size: 1vw;
  line-height: 2vh;
  color: #ffffff;
  font-weight: 400;

  text-align: left;
}
#batting_summary_bowler_name {
  font-size: 1vw;
  line-height: 2vh;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
    padding-left: 2.6vw;
}
#not_out_player_indicator{
  clip-path: polygon(0% 0%, 55% 0%, 100% 50%, 57% 99%, 0 100%);
  background-color: #BFFE01;
  height: 5.1vh;
  width: 0.935vw;
  position: absolute;
  left: 1.5vw;
  margin-top: -1.35vh;
}
#batting_summary_player_runs {
  background-color: #e50785;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 3.3vh;
  text-transform: uppercase;
  height: 5vh;
  width: 8.125vw;
  color: white;
  margin-top:0.8vh;
  position: relative;
}
#t1_player_balls{
  font-size: 1.7vh;
  font-weight: 600;
  line-height: 2.5vh;
  /* position: absolute; */
  margin-left: 0.5vh;
  margin-top: 0.5vh;
  /* background-color: #001030; */

}

#extras_bg {
  background-color: #ebebeb;
  height: 5vh;
  font-size: 1vw;
  width: 41vw;
  margin-left: 1vw;
  font-weight: 600;
  line-height: 1vw;
  text-transform: uppercase;
  /* margin-top: 1.5vh !important; */
  padding-left: .6vw;
  padding-right: 1.8vw;
  color: #68696d;
  position: absolute;
  top: 2.1vh;
}
#batting_summary_result {
  font-size: 1.9vw;
  line-height: 3.8vh;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #bffe01;
  width: 16.5vw;
  height: 6.6vh;
  /* margin-top: 3.7vh !important; */
  position: absolute;
  left: 49vw;
  top: 4.1vh;
}
#extras_row{
  position:absolute;
  top: 70.3vh !important;
}
/* #extras_bg {
  background-color: #ebebeb;
  height: 5vh;
  font-size: 1vw;
  width: 41vw;
  margin-left: 0.7vw;
  font-weight: 600;
  line-height: 1vw;
  text-transform: uppercase;
  
  color: #68696d;
}
#batting_summary_result {
  font-size: 1.875vw;
  line-height: 4.5vh;
  color: #001030;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #bffe01;
  height: 6.6vh;
  width: 15.5vw;
  position: absolute;
  left: 41.6vw;
  text-align: center;
 
  top: 2.5vh;
} */
#batting_summary_gradient {
  background-repeat: no-repeat;
  width: 34.5vw;
  position: absolute;
  top: 8.3vh;
  left: 52vh;
  height: 67.7vh;
  z-index: 1;
}
#batting_team_players {
  height: 5vh;
  width: 48vw;
  text-align: center;
  background-color: #0b1234;
  color: white;
  padding-top: 1.3vh;
  margin-top: 0.8vh;
}

#batting_outer_gradient_layout {
  background-image: linear-gradient(
    148deg,
    #7dff02 0%,
    rgba(124, 255, 0, 0) 50.87%,
    rgba(124, 255, 0, 0) 100%
  );
  height: 52.3vh;
  width: 24.4vw;
  transform: rotate(115deg);
  position: absolute;
  left: 61vw;
  top: 15vh;
  z-index: 0;
}
#batting_inner_gradient_layout {
  background-image: linear-gradient(
    155deg,
    #bffe01 0%,
    rgba(191, 254, 1, 0) 41.68%,
    rgba(191, 254, 1, 0) 97.71%
  );
  height: 52.3vh;
  width: 24.4vw;
  transform: rotate(115deg);
  position: absolute;
  left: 59vw;
  top: 16vh;
  z-index: 1;
}

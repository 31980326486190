#match_summary_bg_white {
  height: 80vh;
  width: 58.75vw;
  background-color: #ffffffff;
  z-index: 99;
  font-family: "Oswald";
}
#match_summary_text {
  height: 10.42vh;
  width: 25.8vw;
  background-color: #bffe01;
  position: absolute;
  top: 0;
  left: 8.75vw;
  font-size: 2.2vw;
  font-weight: 600;
  line-height: 5.4vh;
  text-transform: uppercase;
  padding-left: 2.2vw;
}
#teama_summary_bg {
  height: 7vh;
  width: 58.75vw;
  background-color: #ebebeb;
  font-size: 1.8vw;
  line-height: 4.5vh;
  font-weight: 500;
  text-transform: uppercase;
  color: "#001030";
  margin-top: 6.25vh;
  padding: 1vw;
}
#teamb_summary_bg {
  height: 7vh;
  width: 58.75vw;
  background-color: #ebebeb;
  font-size: 1.8vw;
  line-height: 4.5vh;
  font-weight: 500;
  text-transform: uppercase;
  color: "#001030";
  margin-top: 1.66vh;
  padding: 1vw;
}
#summary_teama_full_name {
  font-size: 1vw;
  line-height: 4.5vh;
  color: #001030;
  font-weight: 400;
  text-align: left;
}
#summary_teama_short_name {
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 4.5vh;
}
#teama_summary_runs {
  font-size: 1.8vw;
  line-height: 4.5vh;
  font-weight: 600;
  text-transform: uppercase;
  color: #001030;
}
#teama_summary_overs {
  font-size: 1vw;
  line-height: 4.5vh;
  color: #68696d;
  padding: 1vw;
}
#summary_row_width {
  padding-left: 1vw;
  padding-right: 1vw;
}
#teama_summary_player_name {
  background-color: #0b1234;
  width: 19.3vw;
  height: 5vh;
  color: white;
  font-size: 1vw;
  margin-top: 1vh;
  padding-left: 1vw;
  line-height: 2.9vh;
  font-weight: 500;
  text-transform: uppercase;
}
#teama_summary_player_runs {
  background-color: #e50785;
  width: 10.5vw;
  height: 5vh;
  color: white;
  margin-top: 1vh;
  font-size: 1.25vw;
  line-height: 3.33vh;
  font-weight: 600;
  text-transform: uppercase;
}
#summary_left_gradient_effect {
  position: absolute;
  z-index: 0;
  bottom: 9.8vh;
  left: 10.41vw;
}
#bottom_result_text {
  height: 10.4vh;
  width: 67.8vw;
  background-color: #0b1234;
  position: absolute;
  bottom: 5.2vh;
  left: 15.9vw;
  border-radius: 8px;
  z-index: 99;
  font-size: 1.8vw;
  font-weight: 600;
  line-height: 4.5vh;
  border: 4px solid #bffe01;
  text-transform: uppercase;
}
#summary_right_gradient_effect {
  position: absolute;
  z-index: 0;
  bottom: 9.8vh;
  left: 54vw;
}
#summary_left_outer_gradient_layout {
  background-image: linear-gradient(
    148deg,
    #7dff02 0%,
    rgba(124, 255, 0, 0) 50.87%,
    rgba(124, 255, 0, 0) 100%
  );
  height: 53.3vh;
  width: 24.5vw;
  transform: rotate(-20deg);
  position: absolute;
  left: 15.3vw;
  top: 25vh;
  z-index: 0;
}
#summary_left_inner_gradient_layout {
  background-image: linear-gradient(
    155deg,
    #bffe01 0%,
    rgba(191, 254, 1, 0) 41.68%,
    rgba(191, 254, 1, 0) 97.71%
  );
  height: 52.3vh;
  width: 24.5vw;
  transform: rotate(-20deg);
  position: absolute;
  left: 17.5vw;
  top: 27vh;
  z-index: 2 !important;
}
#summary_right_outer_gradient_layout {
  background-image: linear-gradient(
    155deg,
    #7dff02 0%,
    rgba(191, 254, 1, 0) 41.68%,
    rgba(191, 254, 1, 0) 97.71%
  ) !important;
  height: 52.3vh;
  width: 24.5vw;
  transform: rotate(115deg);
  position: absolute;
  left: 60.5vw;
  top: 25.1vh;
  z-index: 0;
}
#summary_right_inner_gradient_layout {
  background-image: linear-gradient(
    148deg,
    #bffe01 0%,
    rgba(124, 255, 0, 0) 50.87%,
    rgba(124, 255, 0, 0) 100%
  ) !important;
  height: 52.3vh;
  width: 24.5vw;
  transform: rotate(115deg);
  position: absolute;
  left: 58.3vw;
  top: 27vh;
  z-index: 2 !important;
}
#teama_summary_player_balls {
  color: #fff;
  text-align: right;
  font-size: 1vw;
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vh;
  text-transform: uppercase;
  margin-top: 0.5vh;
  /* background-color: #001030; */
  margin-left: 0.6vw;
}
#player_text_width{
  width: 11.6vw !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space:nowrap;
  text-align: left;
}
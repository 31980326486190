#t2_player_info{
    font-family: "Inter";
}

#t2_player_info_bg_img {
  position: absolute;
  top: 28vh;
  left: 5.2vw;
}
#t2_player_info_bg_img img {
  width: 29.6vw;
  height: 53.2vh;
}
#t2_player_info_name_bg {
  width: 26vw;
  height: 13.8vh;
  background-image: linear-gradient(
    180deg,
    #918171 0%,
    #ece5d7 45.98%,
    #f1e7d6 56.55%,
    #c4b6a4 92.47%
  );
  border-radius: 5.2vw;
  top: 22vh;
  position: absolute;
  left: 6.9vw;
}
#t2_player_info_name_bg img {
  width: 13.7vh;
  height: 13.7vh;
}
#t2_player_info_details_bg {
  position: absolute;
  /* left: ; */
  top: 17vh;
}
#t2_player_info_matches_bg {
  margin-top: 0.8vh;
  width: 26vw;
  height: 5.4vh;
  background-image: linear-gradient(180deg, #002513 0%, #003623 100%);
  border-radius: 1.45vw;
}
#t2_player_info_matches_text {
  width: 13vw;
  height: 5.4vh;
  background-image: linear-gradient(#ffffff, #d6d6d6);
  border-radius: 1.45vw;
  padding-left: 0.8vw;
  position: absolute;
  padding-left: 1vw;
  padding-top: 1.25vh;
  font-size: 1vw;
  font-weight: 600;
  line-height: 2.9vh;
  text-transform: uppercase;
}
#t2_player_info_matches {
  margin-left: 16.9vw;
  margin-top: -3.3vh;
  font-size: 1.4vw;
  font-weight: 700;
  line-height: 3.75vh;
  text-transform: uppercase;
  color: #ffffff;
}
#t2_player_name {
  position: absolute;
  left: 7.9vw;
  font-size: 1.25vw;
  top: 5.5vh;
  font-weight: 700;
  line-height: 2.9vh;
  text-transform: uppercase;
}
#t2_player_type{
    position: absolute;
    left:7.9vw;
    top: 7.2vh;
    font-size: 1vw;
    font-weight: 600;
    line-height: 2.9vh;
    text-transform: uppercase;
    color: #001030 !important;
}
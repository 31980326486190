#before_start_container {
  font-family: "Inter";
}
#t2_bottom_layer {
  position: absolute;
  top: 92vh;
  left: 0;
  height: 7vh;
  width: 100vw;
  background-image: linear-gradient(
    85deg,
    #97897b 0%,
    #7b7166 13.02%,
    #6e665a 29.69%,
    #60574e 51.56%,
    #504a42 69.79%,
    #2f2b26 100%
  );
  font-size: 1vw;
  line-height: 2.9vh;
  font-weight: 700;
  color: #ffffff;
  font-family: "Inter";
  text-transform: uppercase;
}
#t2_teams_bg img {
  position: absolute;
  top: 79.2vh;
  left: 4.2vw;
  width: 92.7vw;
  height: 13vh;
}
#t2_start_teama_logo {
  position: absolute;
  left: 13.4vw;
  top: 80.5vh;
  z-index: 1;
  
}
#t2_start_teama_logo img {
  height: 10vh;
  width: 10vh;
  border: 0.2vw solid #918171;
  border-radius: 5vw;
  
}
#t2_start_teama_short_name {
  position: absolute;
  top: 81.9vh;
  left: 20.5vw;
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 4.5vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
}
#t2_start_teama_name {
  position: absolute;
  top: 86.5vh;
  left: 20.5vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
}
#t2_start_vs_text {
  position: absolute;
  top: 82.4vh;
  left: 48.5vw;
  font-size: 2.7vw;
  font-weight: 800;
  line-height: 6.25vh;
  z-index: 1;
  text-transform: uppercase;
  color: #003125;
}
#t2_start_teamb_short_name {
  position: absolute;
  top: 81.5vh;
  margin-left: 66.5vw;
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 4.5vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
  text-align: right;
}
#t2_start_teamb_name {
  position: absolute;
  left: 66.5vw;
  top: 86.5vh;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
}
#t2_start_teamb_logo {
  position: absolute;
  left: 81.5vw;
  top: 80.7vh;
  z-index: 1;
}
#t2_start_teamb_logo img {
  height: 10vh;
  width: 10vh;
  border: 0.2vw solid #918171;
  border-radius: 5vw;
}

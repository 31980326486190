:root {
  --white_gradient_start_color: #eaeaea;
  --white_gradient_middle_color: #fff;
  --white_gradient_end_color: #eaeaea;
  --blue_gradient_start_color: #568cfe;
  --blue_gradient_middle_color: #2050bc;
  --blue_gradient_end_color: #1e4796;
  --orange_gradient_start_color: #ff9469;
  --orange_gradient_middle_color: #e4602b;
  --orange_gradient_end_color: #d83e22;
  --blue_gradient_start_bg: #193b83;
  --blue_gradient_end_bg: rgba(13, 29, 64, 0.31);

  --title_text_black_color: #1a1717;
  --player_name_text_black_color: #001030;
  --white_text_color: #ffffff;

  --border_bottom_white_color: #fff;
}
#t3_match_bg {
  font-family: "Inter";
}

#t3_match_title_bg {
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--white_gradient_start_color) 0%,
    var(--white_gradient_middle_color) 53.12%,
    var(--white_gradient_end_color) 100%
  );
  height: 12.8vh;
  /* left: 15.6vw; */
  /* position: absolute; */
  top: 8.6vh;
  color: var(--title_text_black_color);
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

#t3_match_title_bg img {
  height: 7vh;
  width: 7vh;
  /* margin-left: 5vh; */
  border-radius: 7vh;
  border: 0.2vh solid #10629e;
  background-size: cover;
  object-fit: cover;
  background-color: #001030;
}
#t3_match_teama_name {
  font-size: 4.5vh;
  font-weight: 400;
  line-height: 2.9vh;
  color: #1a1717;
  text-transform: uppercase;
  position: absolute;
  left: 10vh;
  display: flex;
  align-items: center;
  gap: 2.8vh;
}
#t3_match_text {
  /* width: 41.5vw; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: 4.5vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: #1a1717;
}
#t3_match_teamb_name {
  font-size: 4.5vh;
  font-weight: 400;
  line-height: 2.9vh;
  color: #1a1717;
  position: absolute;
  right: 10vh;
  gap: 2.8vh;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
/* #t3_match_teamb_img img {
  margin-left: 1.45vw !important;
  border-radius: 7vh;
  margin-right: 3.5rem;
} */
#t3_match_blue_bg {
  height: 74.3vh;
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--blue_gradient_start_bg) 30.73%,
    var(--blue_gradient_end_bg) 100%
  );
  position: absolute;
  left: 15.6vw;
  top: 21.2vh;
}
#t3_match_teama_bg {
  height: 8.3vh;
  width: 56.8vw;
  margin-left: 5.8vw;
  background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%),
    linear-gradient(180deg, #568cfe 0%, #2050bc 53.48%, #1e4796 100%);
  margin-top: 2.9vh;
  display: flex;
  align-items: center;
}
#t3_match_bottom_teama_name {
  font-size: 4.5vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: #1a1717;
  text-transform: uppercase;
  margin-left: 1.45vw;
}
#t3_match_teama_short_name {
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: #1a1717;
  text-transform: uppercase;
}
#t3_match_teama_runs {
  position: absolute;
  font-size: 3.7vh;
  line-height: 4.5vh;
  font-weight: 500;
  color: #001030;
  left: 47.4vw;
  display: flex;
  align-items: center;
  gap: 1.6vh;
  /* background-color: #193b83; */
}
#t3_match_teama_overs {
  /* position: absolute; */
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: #001030;
  right: 0;
  /* margin-right: 13.2vh; */
  /* left: 55.1vw; */

  /* background-color: #193b83; */
  /* top: 0vh; */
}
#t3_match_row {
  margin-left: 5.8vw;
  margin-top: 0.4vh;
  width: 58.3vw !important;
}
#t3_match_player_name_bg {
  width: 15.9vw;
  height: 5.3vh;
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
}
#t3_match_player_type_bg {
  height: 5.3vh;
  width: 11.7vw;
  background-image: linear-gradient(
    180deg,
    var(--orange_gradient_start_color) 0%,
    var(--orange_gradient_middle_color) 48.96%,
    var(--orange_gradient_end_color) 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  text-align: right;
}
#t3_match_player_runs {
  font-size: 2.5vh;
  /* background-color: #bcbcbc; */
  font-weight: 600;
  line-height: 3.3vh;
  color: white;
  margin-left: 3.95vw;
  text-align: right;
  display: flex;
  justify-content: right;
  width: 2vw;
}
#t3_match_bowler_data {
  font-size: 2.5vh;
  /* background-color: #bcbcbc; */
  font-weight: 600;
  line-height: 3.3vh;
  color: white;
  margin-left: 3.95vw;
  text-align: right;
  display: flex;
  justify-content: right;
  width: 4vw;
}
#t3_match_player_balls {
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  margin-top: 0.3vh;
  margin-left: 0.8vw;
}
#t3_matchb_player_name_bg {
  width: 15.9vw;
  height: 5.3vh;
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  border-bottom: 0.1vh solid white;
  margin-left: 2.9vw;
  margin-right: 0;
  display: flex;
  align-items: center;
}

#t3_match_player_name {
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  color: white;
  margin-left: 1.7vw;
  text-transform: uppercase;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#t3_match_result_bg {
  width: 68.75vw;
  height: 8.3vh;
  background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%),
    linear-gradient(180deg, #568cfe 0%, #2050bc 53.48%, #1e4796 100%);
  margin-top: 1.3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 66vh;
}
#t3_match_result_text {
  font-size: 3.7vh;
  font-weight: 500;
  line-height: 4.5vh;
  color: #001030;
  text-transform: uppercase;
}

#t3_player_info{
    font-family: "Inter" !important;

}
#t3_player_title_bg {
  height: 12.8vh;
  width: 26.8vw;
  background-image: linear-gradient(
    180deg,
    #eaeaea 0%,
    #fff 53.12%,
    #eaeaea 100%
  );
  margin-top: 23.6vh;
  margin-left: 3.6vw;
  display: flex;
  align-items: center;
  position: absolute;
}
#t3_player_name {
  width: 15.5vw;
  /* background-color: #001030; */
  height: 8.5vh;
  margin-left: 1.25vw;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
#t3_player_name img {
  height: 8.7vh;
  width: 8.7vh;
  border-radius: 4.5vh;
  /* position: absolute; */
  /* margin-left: 1.25vw; */
  /* margin-top: 2vh; */
}
#t3_player_name span {
  /* position: absolute; */
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.7vh;
  color: #001030;
  margin-left: 0.4vw;
  /* margin-top: 4.5vh; */
  text-transform: uppercase;
}
#t3_next_bat_bg {
  height: 12.8vh;
  width: 8.3vw;
  background-image: linear-gradient(
    180deg,
    #ff9469 0%,
    #e4602b 48.96%,
    #d83e22 100%
  );
  margin-left: 18.5vw;
  display: flex;
  align-items: center;
  position: absolute;
}
#t3_next_bat_text {
  font-size: 3vh;
  font-weight: 500;
  line-height: 4.1vh;
  color: white;
  margin-left: 0.8vw;
}
#t3_next_bat_text span {
  font-weight: 700;
}
#t3_player_info_bg {
  height: 28.2vh;
  width: 26.8vw;
  background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%);
  margin-left: 3.6vw;
  position: absolute;
  margin-top: 36vh;
}
#t3_player_titles {
  margin-top: 2.5vh;
  margin-left: 1.25vw;
  gap: 2.4vh;
}
#t3_player_matches {
  height: 10.3vh;
  width: 7.2vw;
  background-color: #193b83;
  border-left: 0.1vh solid transparent;
  border-right: 0.1vh solid transparent;
  border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
  font-size: 1.9vh;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
}
#t3_player_innings {
  height: 10.3vh;
  width: 7.2vw;
  background-color: #193b83;
  border-left: 0.1vh solid transparent;
  border-right: 0.1vh solid transparent;
  border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
}
#t3_player_runs {
  height: 10.3vh;
  width: 7.2vw;
  background-color: #193b83;
  border-left: 0.1vh solid transparent;
  border-right: 0.1vh solid transparent;
  border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
}
#t3_player_data{
    margin-top: 0.8vh;
    font-size: 2.9vh;
    font-weight: 500;
    line-height: 3.3vh;
}
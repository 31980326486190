#bowling_summary_white_bg {
  background-color: white;
  width: 58.75vw;
  z-index: 9;
  height: 79vh;
  font-family: "Oswald";
}
#bowling_summary_team_name_bg {
  background-color: #bffe01;
  width: 49.2vw;
  height: 9.5vh;
  position: absolute;
  left: -8vw;
  z-index: 1;
}
#bowling_summary_logo_outer_gradient {
  width: 8.11vw;
  height: 16.5vh;
  transform: rotate(50deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  left: -5vw;
}
#bowling_summary_logo_inner_gradient {
  width: 6.53vw;
  height: 12.32vh;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#bowling_summary_team_logo {
  transform: rotate(-50deg);
  height: 7vh;
  width: 3.5vw;
  border-radius: 50%;
}
#bowling_summary_player_runs_text{
  width: 36.9vw;
}
#bowling_content {
  padding: 1.8vw;
}
#bowling_summary_team_name {
  font-size: 2.2vw;
  color: #001030;
  font-weight: 600;
  margin-left: 6.25vw;
  line-height: 5.4vh;
  text-transform: uppercase;
}
#bowling_summary_text {
  padding-right: 1vw;
  padding-bottom: 1vh;
  text-transform: uppercase;
  font-size: 1vw;
  font-weight: 500;
  display: flex;
  /* justify-content: center; */
  align-items: end;
}
#bowling_summary_empty_bg {
  height: 5vh;
  background-color: #ebebeb;
  margin-top: 3vh;
  width: 19vw;
}
#bowling_summary_heading {
  height: 5vh;
  background: #ebebeb;
  font-size: 1vw;
  padding-top: 1vh;
  line-height: 3vh;
  font-weight: 400px;
  color: #0b1234;
  text-transform: uppercase;
  margin-top: 3vh;
  /* text-align: justify; */
  width: 37vw;
}
#bowling_summary_player_name {
  background-color: #0b1234;
  font-size: 1vw;
  margin-top: 0.8vh;
  font-weight: 500;
  padding-left: 2.5vh;
  height: 5vh;
  /* text-align: center; */
  width: 19vw;
  line-height: 3.3vh;
  color: white;
  text-transform: uppercase;
}
#bowling_summary_player_runs {
  padding-left: 2vh;
  /* text-align: justify; */
  height: 5vh;
  margin-top: 0.8vh;
  width: 37vw;
  background-color: #e50785;
  font-size: 1.3vw;
  line-height: 3.3vh;
  font-weight: 600;
  display: grid;
  align-items: center;
  color: white;

}
#fall_of_wickets_text {
  background-color: #0b1234;
  font-size: 1vw;
  margin-top: 12vh;
  font-weight: 500;
  padding-left: 2vh;
  height: 5vh;
  /* text-align: justify; */
  width: 19vw;
  line-height: 3.3vh;
  text-transform: uppercase;
}
#fall_of_wickets_bg {
  height: 5.2vh;
  background-color: #ebebeb;
  font-size: 1.3vw;
  font-weight: 600;
  line-height: 4.5vh;
  margin-top: 12vh;
  display: grid !important;
  width: 37vw;
  padding-left: 1vw;
  color: #68696d;
 
}
#bowling_scores_nums {
  height: 5vh;
  background-color: #ebebeb;
  font-size: 1.3vw;
  font-weight: 600;
  color: #0b1234;
  display: grid !important;
  line-height: 4.5vh;
  width: 37vw;
  margin-top: 0.8vh;
  padding-left: 1vw;
  text-align: center;
}
#bowling_summary_extras_bg {
  background-color: #ebebeb;
  height: 5vh;
  font-size: 1vw;
  width: 41vw;
  margin-left: 0.7vw;
  font-weight: 600;
  line-height: 1vw;
  text-transform: uppercase;
  margin-top: 1.5vh !important;
  color: #68696d;
  padding: 1vw;
  padding-left:1vw;
}
#bowling_summary_runs_bg {
  font-size: 1.9vw;
  line-height: 3.8vh;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #bffe01;
  width: 16.5vw;
  height: 6.6vh;
  margin-top: 4vh !important;
  position: absolute;
  left: 49vw;
}
#bowling_outer_gradient_layout {
  background-image: linear-gradient(
    148deg,
    #7dff02 0%,
    rgba(124, 255, 0, 0) 50.87%,
    rgba(124, 255, 0, 0) 100%
  );
  height: 52.3vh;
  width: 24.4vw;
  transform: rotate(115deg);
  position: absolute;
  left: 61vw;
  top: 15vh;
  z-index: 0;
}
#bowling_inner_gradient_layout {
  background-image: linear-gradient(
    155deg,
    #bffe01 0%,
    rgba(191, 254, 1, 0) 41.68%,
    rgba(191, 254, 1, 0) 97.71%
  );
  height: 52.3vh;
  width: 24.4vw;
  transform: rotate(115deg);
  position: absolute;
  left: 59vw;
  top: 16vh;
  z-index: 1;
}
#scores_row{
  top: 64vh;
  position: absolute;
}
#fall_of_wickets_row{
  top: 47vh;
  position: absolute;
}
@media only screen and (min-height: 360px) and (max-height: 400px) {
  #bowling_summary_runs_bg {
    font-size: 1.9vw;
    line-height: 3.8vh;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #bffe01;
    width: 16.5vw;
    height: 6.6vh;
    margin-top: 4vh !important;
    position: absolute;
    left: 51vw;
  }
}
#tikcer_left_pink_border {
  height: 0;
  width: 34.1vw;
  left: 2.6vw !important;
  border-bottom: 4.8vw solid #e50785;
  border-left: 3.35vw solid transparent;
  border-right: 0vw solid transparent;
  position: absolute;
  margin-top: 0vh;
  top: 1.6vh;
  z-index: 0 !important;
  font-family: "Oswald";
}
#ticker_left_green_layer {
  height: 0;
  width: 33.7vw;
  left: 3vw !important;
  border-bottom: 4.5vw solid #bffe01;
  border-left: 3.08vw solid transparent;
  border-right: 0vw solid transparent;
  position: absolute;
  /* margin-top: 2vh; */
  top: 2vh;
  z-index: 0 !important;
}

#ticker_white_layer {
  background: white;
  width: 26.5vw;
  height: 8.1vh;
  margin-left: 36.7vw;
  z-index: 99 !important;
}
#ticker_pink_layer {
  width: 11.6vw;
  height: 12.1vh;
  transform: skew(-20deg);
  background: #e50785;
  position: absolute;
  margin-left: 7.5vw;
  display: flex;
  justify-content: center;
}
#tikcer_right_pink_border {
  /* height: 0;
  width: 35.8vw;
  left: 63.2vw !important;
  border-bottom: 4.8vw solid #e50785;
  border-left: 0vw solid transparent;
  border-right: 2.15vw solid transparent;
  position: absolute;
  top: 2.8vh; */

  height: 0;
  width: 34.1vw !important;
  left: 63.2vw !important;
  border-bottom: 4.8vw solid #e50785;
  border-left: 0vw solid transparent;
  border-right: 3.35vw solid transparent;
  position: absolute;
  margin-top: 0vh;
  top: 1.6vh !important;
  z-index: 0 !important;
  /* transform: skew(20deg); */
}

#ticker_right_green_layer {
  /* height: 0;
  width: 33.7vw;
  left: 63.2vw !important;
  border-bottom: 4.8vw solid #bffe01;
  border-left: 0vw solid transparent;
  border-right: 2.08vw solid transparent;
  position: absolute;
  top: 2vh; */
  height: 0;
  width: 33.9vw !important;
  left: 63vw !important;
  border-bottom: 4.5vw solid #bffe01;
  border-left: 0vw solid transparent;
  border-right: 3.08vw solid transparent;
  position: absolute;
  /* margin-top: 2vh; */
  top: 2vh;
  z-index: 0 !important;
}
#t1_striker_arrow {
  width: 0;
  height: 0;
  border-top: 1.4vh solid transparent;
  border-bottom: 1.4vh solid transparent;
  border-left: 1.4vh solid #e50785;
  position: absolute;
  top: 1.5vh;
  left: 4.5vw;
}
#t1_player1_text {
  font-size: 3.7vh;
  font-weight: 500;
  line-height: 4.5vh;
  color: #001030;
  position: absolute;
  left: 13vh !important;
  top: 0.8vh;
  text-transform: uppercase;
}
#t1_player_runs {
  font-size: 3.7vh;
  line-height: 4.4vh;
  color: #001030;
  position: absolute;
  left: 48vh;
  top: 0.8vh;
  font-weight: 400;
}
#t1_teama_name {
  position: absolute;
  top: 2vh;
  left: 38.8vw;
  font-size: 3.3vh;
  font-weight: 500;
  line-height: 4.1vh;
  color: #001030;
  text-transform: uppercase;
  /* background: #6483c2; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.9vh;
}
#t1_runs_wickets {
  position: absolute;
  top: 0.4vh;
  display: flex;
  align-items: center;
  justify-content: center !important;
  /* left: 2vw; */
  font-size: 5.8vh;
  font-weight: 700;
  color: white;
  line-height: 6.6vh;
  transform: skew(20deg);
}
#t1_vs_text {
  position: absolute;
  left: 57.1vw;
  top: 1.8vh;
  font-size: 3.2vh;
  font-weight: 500;
  color: #00103066;
  line-height: 4.9vh;
}
#t1_chasing_team {
  position: absolute;
  font-weight: 500;
  font-size: 2vh;
  top: 0.4vh;
  left: 2vw;
  line-height: 4.1vh;
  color: #00103066;
  text-transform: uppercase;
}
#t1_non_striker_arrow {
  width: 0;
  height: 0;
  border-top: 1.4vh solid transparent;
  border-bottom: 1.4vh solid transparent;
  border-left: 1.4vh solid #e50785;
  position: absolute;
  top: 1.8vh;
  left: 3.5vw;
}
#t1_player2_text {
  position: absolute;
  left: 5.4vw;
  font-size: 3.7vh;
  font-weight: 500;
  line-height: 4.5vh;
  color: #001030;
  text-transform: uppercase;
  top: 0.8vh;
}
#t2_player_runs {
  position: absolute;
  font-size: 3.7vh;
  line-height: 4.4vh;
  left: 20.4vw;
  font-weight: 400;
}
#ticker_blue_layer {
  height: 3.75vw;
  width: 100vw;
  background-color: #01102b;
  font-size: 1.25vw;
  line-height: 3.33vh;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
}
#base {
  width: 16.6vw;
  height: 6.6vh;
  background: #bffe01;
  position: absolute;
  left: 41.6vw;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 0% 55%, 50% 100%, 100% 55%, 100% 0%, 50% 0%);
}
#partnership_bg {
  position: absolute;
  font-size: 2vh;
  font-weight: 400;
  line-height: 2.9vh;
  color: white;
  display: flex;
  align-items: center;
  left: 3.6vw;
  gap: 3.2vh;
  /* justify-content: center; */
}
#partner_runs {
  font-weight: 500;
}
#t1_overs_text {
  font-size: 2.4vh;
  font-weight: 500;
  line-height: 3.3vh;
  color: #001030;
  position: absolute;
  top: 1.5vh;
}
#t1_bowler_name {
  font-size: 2.4vh;
  font-weight: 500;
  line-height: 3.3vh;
  color: white;
  position: absolute;
  left: 59.9vw;
}
#t1_bowler_runs_balls {
  font-size: 2.4vh;
  font-weight: 400;
  line-height: 3.3vh;
  color: white;
  margin-left: 3.4vw;
}
#t1_current_balls {
  display: flex;
  align-items: center;
  justify-content: right;
  position: absolute;
  left: 75vw;
  overflow-x: scroll !important;
  /* direction: rtl !important; */
}

#t1_single_balls {
  height: 3.5vh !important;
  width: 3.5vh !important;
  background-color: white;
  border-radius: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.2vh;
  direction: rtl;
}
#information {
  transition: 3s ease-in !important;
  transform: translateY(10deg);
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  direction: rtl !important;
}

/* screen 1920*1080 */

@media only screen and (min-width: 1920px) and (min-height: 1080px) {
  #ticker_blue_layer {
    height: 3.75vw;
    width: 100vw;
    background-color: #01102b;
    font-size: 1.25vw;
    line-height: 3.33vh;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
  }
  #tikcer_left_pink_border {
    height: 0;
    width: 34.09vw;
    left: 2.6vw !important;
    border-bottom: 4.8vw solid #e50785;
    border-left: 3.35vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    margin-top: 0vh;
    top: 1.8vh;
    z-index: 0 !important;
  }
  #ticker_left_green_layer {
    height: 0;
    width: 33.7vw;
    left: 3vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 3.08vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 2.2vh;
    z-index: 0 !important;
  }

  #ticker_white_layer {
    background: white;
    width: 26.5vw;
    height: 9vh;
    margin-left: 36.7vw;
    z-index: 99 !important;
  }
  #ticker_pink_layer {
    width: 11.6vw;
    height: 12.1vh;
    transform: skew(-20deg);
    background: #e50785;
    position: absolute;
    margin-left: 7.5vw;
  }
  #tikcer_right_pink_border {
    /* height: 0;
    width: 35.8vw;
    left: 63.2vw !important;
    border-bottom: 4.8vw solid #e50785;
    border-left: 0vw solid transparent;
    border-right: 2.15vw solid transparent;
    position: absolute;
    top: 1.8vh; */
    height: 0;
    width: 34.3vw !important;
    left: 63.19vw !important;
    border-bottom: 4.8vw solid #e50785 !important;
    border-left: 0vw solid transparent;
    border-right: 3.35vw solid transparent !important;
    position: absolute;
    margin-top: 0vh;
    top: 1.8vh !important;
    z-index: 0 !important;
  }
  #ticker_right_green_layer {
    /* height: 0;
    width: 33.3vw;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 2.08vw solid transparent;
    position: absolute;
    top: 2vh; */
    height: 0;
    width: 33.7vw;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 3.08vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 2.2vh;
    z-index: 0 !important;
    /* margin-top: 2vh; */
  }
  #base {
    width: 16.6vw;
    height: 6vh;
    background: #bffe01;
    position: absolute;
    left: 41.6vw;
    clip-path: polygon(0% 0%, 0% 55%, 50% 100%, 100% 55%, 100% 0%, 50% 0%);
  }
  #t1_overs_text {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: #001030;
    position: absolute;
    top: 1vh;
  }
  #t1_player1_text {
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    position: absolute;
    left: 11vh;
    top: 1vh;
    text-transform: uppercase;
  }
  #t1_player_runs {
    font-size: 3.7vh;
    line-height: 4.4vh;
    color: #001030;
    position: absolute;
    left: 40vh;
    top: 1vh;
    font-weight: 400;
  }
  #t1_teama_name {
    position: absolute;
    top: 2.5vh;
    left: 38.8vw;
    font-size: 3.3vh;
    font-weight: 500;
    line-height: 4.1vh;
    color: #001030;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.9vh;
  }
  #t1_runs_wickets {
    position: absolute;
    top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center !important;
    /* left: 2vw; */
    font-size: 5.8vh;
    font-weight: 700;
    color: white;
    line-height: 6.6vh;
    transform: skew(20deg);
  }
  #t1_vs_text {
    position: absolute;
    left: 57.1vw;
    top: 2.2vh;
    font-size: 3.2vh;
    font-weight: 500;
    color: #00103066;
    line-height: 4.9vh;
  }
  #t1_chasing_team {
    position: absolute;
    font-weight: 500;
    font-size: 2vh;
    top: 0.4vh;
    left: 2.5vw;
    line-height: 4.1vh;
    color: #00103066;
    text-transform: uppercase;
  }
  #t1_player2_text {
    position: absolute;
    left: 5.4vw;
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    text-transform: uppercase;
    top: 1vh;
  }
  #t2_player_runs {
    position: absolute;
    font-size: 3.7vh;
    line-height: 4.4vh;
    left: 18vw;
    font-weight: 400;
  }
  #t1_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.4vh solid transparent;
    border-bottom: 1.4vh solid transparent;
    border-left: 1.4vh solid #e50785;
    position: absolute;
    top: 1.8vh;
    left: 4.5vw;
  }
  #t1_non_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.4vh solid transparent;
    border-bottom: 1.4vh solid transparent;
    border-left: 1.4vh solid #e50785;
    position: absolute;
    top: 1.8vh;
    left: 3.5vw;
  }
}
@media only screen and (min-height: 360px) and (max-height: 400px) {
  #tikcer_left_pink_border {
    height: 0;
    width: 34.1vw;
    left: 2.6vw !important;
    border-bottom: 4.8vw solid #e50785 !important;
    border-left: 3.35vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    margin-top: 0vh;
    top: 3.8vh !important;
    z-index: 0 !important;
  }
  #ticker_left_green_layer {
    height: 0;
    width: 33.7vw;
    left: 3vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 3.08vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 4.28vh;
    z-index: 0 !important;
  }
  #t1_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.4vh solid transparent;
    border-bottom: 1.4vh solid transparent;
    border-left: 1.4vh solid #e50785;
    position: absolute;
    top: 2.8vh;
    left: 4.5vw;
  }
  #ticker_white_layer {
    background: white;
    width: 26.5vw;
    height: 12.1vh;
    margin-left: 36.7vw;
    z-index: 99 !important;
  }
  #ticker_pink_layer {
    width: 11.6vw;
    height: 12.1vh;
    transform: skew(-20deg);
    background: #e50785;
    position: absolute;
    margin-left: 7.5vw;
    display: flex;
    justify-content: center;
  }
  #tikcer_right_pink_border {
    height: 0;
    width: 35.8vw;
    left: 63.2vw !important;
    border-bottom: 4.8vw solid #e50785;
    border-left: 0vw solid transparent;
    border-right: 2.15vw solid transparent;
    position: absolute;
    top: 3.8vh !important;
  }
  #ticker_right_green_layer {
    height: 0;
    width: 35.5vw;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 2.08vw solid transparent;
    position: absolute;
    top: 4.28vh;
    /* margin-top: 2vh; */
  }

  #t1_player1_text {
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    position: absolute;
    left: 12vh;
    top: 2vh;
    text-transform: uppercase;
  }
  #t1_player_runs {
    font-size: 3.7vh;
    line-height: 4.4vh;
    color: #001030;
    position: absolute;
    left: 40vh;
    top: 2vh;
    font-weight: 400;
  }
  #t1_teama_name {
    position: absolute;
    top: 4vh;
    left: 38.8vw;
    font-size: 3.3vh;
    font-weight: 500;
    line-height: 4.1vh;
    color: #001030;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.9vh;
  }
  #t1_runs_wickets {
    position: absolute;
    top: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center !important;
    /* left: 2vw; */
    font-size: 5.8vh;
    font-weight: 700;
    color: white;
    line-height: 6.6vh;
    transform: skew(20deg);
  }
  #t1_vs_text {
    position: absolute;
    left: 57.1vw;
    top: 3.2vh;
    font-size: 3.2vh;
    font-weight: 500;
    color: #00103066;
    line-height: 4.9vh;
  }
  #t1_chasing_team {
    position: absolute;
    font-weight: 500;
    font-size: 2vh;
    top: 0.4vh;
    left: 2.5vw;
    line-height: 4.1vh;
    color: #00103066;
    text-transform: uppercase;
  }
  #t1_player2_text {
    position: absolute;
    left: 5.4vw;
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    text-transform: uppercase;
    top: 2vh;
  }
  #t2_player_runs {
    position: absolute;
    font-size: 3.7vh;
    line-height: 4.4vh;
    left: 20.4vw;
    font-weight: 400;
  }
  #ticker_blue_layer {
    height: 3.75vw;
    width: 100vw;
    background-color: #01102b;
    font-size: 1.25vw;
    line-height: 3.33vh;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
  }
  #base {
    width: 33.3vh !important;
    height: 5.5vh !important;
    background: #bffe01;
    position: absolute;
    left: 41vw !important;
    top: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  #partnership_bg {
    position: absolute;
    font-size: 1.9vh;
    font-weight: 400;
    line-height: 2.9vh;
    color: white;
    display: flex;
    align-items: center;
    left: 3.6vw;
    gap: 3.2vh;
    /* justify-content: center; */
  }
  #t1_non_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.6vh solid transparent;
    border-bottom: 1.6vh solid transparent;
    border-left: 1.6vh solid #e50785;
    position: absolute;
    top: 2.3vh;
    left: 3.5vw;
  }
  #partner_runs {
    font-weight: 500;
  }
  #t1_overs_text {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: #001030;
    position: absolute;
    top: 1vh;
  }
  #t1_bowler_name {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: white;
    position: absolute;
    left: 60.9vw !important;
  }
  #t1_bowler_runs_balls {
    font-size: 2.4vh;
    font-weight: 400;
    line-height: 3.3vh;
    color: white;
    margin-left: 2vw !important;
  }
  #t1_current_balls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 75vw;
  }
  #t1_single_balls {
    height: 4.5vh !important;
    width: 4.5vh !important;
    background-color: white;
    border-radius: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.2vh;
  }
  .t1_extras_balls {
    font-size: 1vh !important;
    /* line-height: 2vh !important; */
  }
}
@media only screen and (min-height: 400px) and (max-height: 550px) {
  #tikcer_left_pink_border {
    height: 0;
    width: 34.1vw;
    left: 2.6vw !important;
    border-bottom: 4.8vw solid #e50785 !important;
    border-left: 3.35vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    margin-top: 0vh;
    top: 3.8vh !important;
    z-index: 0 !important;
  }
  #t1_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.4vh solid transparent;
    border-bottom: 1.4vh solid transparent;
    border-left: 1.4vh solid #e50785;
    position: absolute;
    top: 2.7vh;
    left: 4.5vw;
  }
  #ticker_left_green_layer {
    height: 0;
    width: 33.7vw;
    left: 3vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 3.08vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 4.28vh;
    z-index: 0 !important;
  }

  #ticker_white_layer {
    background: white;
    width: 26.5vw;
    height: 12.1vh;
    margin-left: 36.7vw;
    z-index: 99 !important;
  }
  #ticker_pink_layer {
    width: 11.6vw;
    height: 12.1vh;
    transform: skew(-20deg);
    background: #e50785;
    position: absolute;
    margin-left: 7.5vw;
    display: flex;
    justify-content: center;
  }
  #tikcer_right_pink_border {
    height: 0;
    width: 35.8vw;
    left: 63.2vw !important;
    border-bottom: 4.8vw solid #e50785;
    border-left: 0vw solid transparent;
    border-right: 2.15vw solid transparent;
    position: absolute;
    top: 3.8vh !important;
  }
  #ticker_right_green_layer {
    height: 0;
    width: 35.5vw;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 2.08vw solid transparent;
    position: absolute;
    top: 4.28vh;
    /* margin-top: 2vh; */
  }

  #t1_player1_text {
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    position: absolute;
    left: 12vh;
    top: 2vh;
    text-transform: uppercase;
  }
  #t1_player_runs {
    font-size: 3.7vh;
    line-height: 4.4vh;
    color: #001030;
    position: absolute;
    left: 40vh;
    top: 2vh;
    font-weight: 400;
  }
  #t1_teama_name {
    position: absolute;
    top: 4vh;
    left: 38.8vw;
    font-size: 3.3vh;
    font-weight: 500;
    line-height: 4.1vh;
    color: #001030;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.9vh;
  }
  #t1_runs_wickets {
    position: absolute;
    top: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center !important;
    /* left: 2vw; */
    font-size: 5.8vh;
    font-weight: 700;
    color: white;
    line-height: 6.6vh;
    transform: skew(20deg);
  }
  #t1_vs_text {
    position: absolute;
    left: 57.1vw;
    top: 3.2vh;
    font-size: 3.2vh;
    font-weight: 500;
    color: #00103066;
    line-height: 4.9vh;
  }
  #t1_chasing_team {
    position: absolute;
    font-weight: 500;
    font-size: 2vh;
    top: 0.4vh;
    left: 2.5vw;
    line-height: 4.1vh;
    color: #00103066;
    text-transform: uppercase;
  }
  #t1_player2_text {
    position: absolute;
    left: 5.4vw;
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    text-transform: uppercase;
    top: 2vh;
  }
  #t2_player_runs {
    position: absolute;
    font-size: 3.7vh;
    line-height: 4.4vh;
    left: 20.4vw;
    font-weight: 400;
  }
  #ticker_blue_layer {
    height: 3.75vw;
    width: 100vw;
    background-color: #01102b;
    font-size: 1.25vw;
    line-height: 3.33vh;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
  }
  #base {
    width: 33.3vh !important;
    height: 5.5vh !important;
    background: #bffe01;
    position: absolute;
    left: 41vw !important;
    top: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  #partnership_bg {
    position: absolute;
    font-size: 1.9vh;
    font-weight: 400;
    line-height: 2.9vh;
    color: white;
    display: flex;
    align-items: center;
    left: 3.6vw;
    gap: 3.2vh;
    /* justify-content: center; */
  }
  #partner_runs {
    font-weight: 500;
  }
  #t1_overs_text {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: #001030;
    position: absolute;
    top: 1vh;
  }
  #t1_bowler_name {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: white;
    position: absolute;
    left: 60.9vw !important;
  }
  #t1_bowler_runs_balls {
    font-size: 2.4vh;
    font-weight: 400;
    line-height: 3.3vh;
    color: white;
    margin-left: 2vw !important;
  }
  #t1_current_balls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 75vw;
  }
  #t1_single_balls {
    height: 4.5vh !important;
    width: 4.5vh !important;
    background-color: white;
    border-radius: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.2vh;
  }
  .t1_extras_balls {
    font-size: 1vh !important;
    /* line-height: 2vh !important; */
  }
}
@media only screen and (min-height: 255px) and (max-height: 300px) {
  #tikcer_left_pink_border {
    height: 0;
    width: 34.1vw;
    left: 2.6vw !important;
    border-bottom: 4.8vw solid #e50785 !important;
    border-left: 3.35vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    margin-top: 0vh;
    top: 2.5vh;
    z-index: 0 !important;
  }
  #ticker_left_green_layer {
    height: 0;
    width: 33.7vw;
    left: 3vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 3.08vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 3.18vh;
    z-index: 0 !important;
  }

  #ticker_white_layer {
    background: white;
    width: 26.5vw;
    height: 12.1vh;
    margin-left: 36.7vw;
    z-index: 99 !important;
  }
  #ticker_pink_layer {
    width: 11.6vw;
    height: 12.1vh;
    transform: skew(-20deg);
    background: #e50785;
    position: absolute;
    margin-left: 7.5vw;
    display: flex;
    justify-content: center;
  }
  #tikcer_right_pink_border {
    /* height: 0;
    width: 34.1vw;
    left: 63.2vw !important;
    border-bottom: 4.8vw solid #e50785;
    border-left: 0vw solid transparent;
    border-right: 2.35vw solid transparent;
    position: absolute;
    top: 2.8vh; */
    height: 0;
    width: 34.1vw !important;
    left: 63.2vw !important;
    border-bottom: 4.8vw solid #e50785 !important;
    border-left: 0vw solid transparent !important;
    border-right: 3.35vw solid transparent !important;
    position: absolute;
    margin-top: 0vh;
    top: 2.5vh !important;
    z-index: 0 !important;
    /* transform: rotate(-360deg); */
  }
  #ticker_right_green_layer {
    height: 0;
    width: 33.7vw !important;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 3.08vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 3.18vh;
    z-index: 0 !important;
    transform: rotate(-360deg);

    /* height: 0;
    width: 33.7vw;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 2.08vw solid transparent;
    position: absolute;
    top: 3.18vh; */
    /* margin-top: 2vh; */
  }
  #t1_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.6vh solid transparent;
    border-bottom: 1.6vh solid transparent;
    border-left: 1.6vh solid #e50785;
    position: absolute;
    top: 3vh;
    left: 3.5vw !important;
  }
  #t1_player1_text {
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    position: absolute;
    left: 13.5vh;
    top: 2vh;
    text-transform: uppercase;
  }
  #t1_player_runs {
    font-size: 3.9vh;
    line-height: 4.4vh;
    color: #001030;
    position: absolute;
    left: 54vh;
    top: 2vh;
    font-weight: 400;
  }
  #t1_teama_name {
    position: absolute;
    top: 4vh;
    left: 38.8vw;
    font-size: 3.8vh;
    font-weight: 500;
    line-height: 4.1vh;
    color: #001030;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.9vh;
  }
  #t1_runs_wickets {
    position: absolute;
    top: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center !important;
    /* left: 2vw; */
    font-size: 5.8vh;
    font-weight: 700;
    color: white;
    line-height: 6.6vh;
    transform: skew(20deg);
  }
  #t1_vs_text {
    position: absolute;
    left: 57.1vw;
    top: 3.5vh;
    font-size: 3.5vh;
    font-weight: 500;
    color: #00103066;
    line-height: 4.9vh;
  }
  #t1_chasing_team {
    position: absolute;
    font-weight: 500;
    font-size: 2.5vh;
    top: 0.2vh;
    left: 2vw;
    line-height: 4.1vh;
    color: #00103066;
    text-transform: uppercase;
  }
  #t1_non_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.6vh solid transparent;
    border-bottom: 1.6vh solid transparent;
    border-left: 1.6vh solid #e50785;
    position: absolute;
    top: 3vh;
    left: 3.5vw;
  }
  #t1_player2_text {
    position: absolute;
    left: 5.4vw;
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    text-transform: uppercase;
    top: 2vh;
  }
  #t2_player_runs {
    position: absolute;
    font-size: 3.9vh;
    line-height: 4.4vh;
    left: 18.4vw;
    font-weight: 400;
  }
  #ticker_blue_layer {
    height: 3.75vw;
    width: 100vw;
    background-color: #01102b;
    font-size: 1.25vw;
    line-height: 3.33vh;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
  }
  #base {
    width: 16.6vw;
    height: 6.6vh;
    background: #bffe01;
    position: absolute;
    left: 41.6vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #partnership_bg {
    position: absolute;
    font-size: 2.5vh;
    font-weight: 400;
    line-height: 2.9vh;
    color: white;
    display: flex;
    align-items: center;
    left: 3.6vw;
    gap: 3.2vh;
    /* justify-content: center; */
  }
  #partner_runs {
    font-weight: 500;
  }
  #t1_overs_text {
    font-size: 2.8vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: #001030;
  }
  #t1_bowler_name {
    font-size: 2.6vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: white;
    position: absolute;
    left: 59.9vw;
  }
  #t1_bowler_runs_balls {
    font-size: 2.8vh;
    font-weight: 400;
    line-height: 3.3vh;
    color: white;
    margin-left: 3.4vw;
  }
  #t1_current_balls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 75vw;
  }
  #t1_single_balls {
    height: 5vh !important;
    width: 5vh !important;
    background-color: white;
    border-radius: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.2vh;
  }
  #t1_single_balls span {
    /* height: 5vh !important; */
    width: 5vh !important;
  }
}
@media only screen and (min-height: 300px) and (max-height: 360px) {
  #tikcer_left_pink_border {
    height: 0;
    width: 34.1vw;
    left: 2.6vw !important;
    border-bottom: 4.8vw solid #e50785 !important;
    border-left: 3.35vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    margin-top: 0vh;
    top: 3.8vh !important;
    z-index: 0 !important;
  }
  #t1_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.4vh solid transparent;
    border-bottom: 1.4vh solid transparent;
    border-left: 1.4vh solid #e50785;
    position: absolute;
    top: 3vh;
    left: 4.5vw;
  }
  #ticker_left_green_layer {
    height: 0;
    width: 33.7vw;
    left: 3vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 3.08vw solid transparent;
    border-right: 0vw solid transparent;
    position: absolute;
    /* margin-top: 2vh; */
    top: 4.28vh;
    z-index: 0 !important;
  }

  #ticker_white_layer {
    background: white;
    width: 26.5vw;
    height: 12.1vh;
    margin-left: 36.7vw;
    z-index: 99 !important;
  }
  #ticker_pink_layer {
    width: 11.6vw;
    height: 12.1vh;
    transform: skew(-20deg);
    background: #e50785;
    position: absolute;
    margin-left: 7.5vw;
    display: flex;
    justify-content: center;
  }
  #tikcer_right_pink_border {
    height: 0;
    width: 35.8vw;
    left: 63.2vw !important;
    border-bottom: 4.8vw solid #e50785;
    border-left: 0vw solid transparent;
    border-right: 2.15vw solid transparent;
    position: absolute;
    top: 3.8vh !important;
  }
  #t1_non_striker_arrow {
    width: 0;
    height: 0;
    border-top: 1.4vh solid transparent;
    border-bottom: 1.4vh solid transparent;
    border-left: 1.4vh solid #e50785;
    position: absolute;
    top: 2.5vh;
    left: 3.5vw;
  }
  #ticker_right_green_layer {
    height: 0;
    width: 35.5vw;
    left: 63.2vw !important;
    border-bottom: 4.5vw solid #bffe01;
    border-left: 0vw solid transparent;
    border-right: 2.08vw solid transparent;
    position: absolute;
    top: 4.28vh;
    /* margin-top: 2vh; */
  }

  #t1_player1_text {
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    position: absolute;
    left: 12vh;
    top: 2vh;
    text-transform: uppercase;
  }
  #t1_player_runs {
    font-size: 3.7vh;
    line-height: 4.4vh;
    color: #001030;
    position: absolute;
    left: 40vh;
    top: 2vh;
    font-weight: 400;
  }
  #t1_teama_name {
    position: absolute;
    top: 4vh;
    left: 38.8vw;
    font-size: 3.3vh;
    font-weight: 500;
    line-height: 4.1vh;
    color: #001030;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.9vh;
  }
  #t1_runs_wickets {
    position: absolute;
    top: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center !important;
    /* left: 2vw; */
    font-size: 5.8vh;
    font-weight: 700;
    color: white;
    line-height: 6.6vh;
    transform: skew(20deg);
  }
  #t1_vs_text {
    position: absolute;
    left: 57.1vw;
    top: 4vh;
    font-size: 3.2vh;
    font-weight: 500;
    color: #00103066;
    line-height: 4.9vh;
  }
  #t1_chasing_team {
    position: absolute;
    font-weight: 500;
    font-size: 2vh;
    top: 0.4vh;
    left: 2.5vw;
    line-height: 4.1vh;
    color: #00103066;
    text-transform: uppercase;
  }
  #t1_player2_text {
    position: absolute;
    left: 5.4vw;
    font-size: 3.7vh;
    font-weight: 500;
    line-height: 4.5vh;
    color: #001030;
    text-transform: uppercase;
    top: 2vh;
  }
  #t2_player_runs {
    position: absolute;
    font-size: 3.7vh;
    line-height: 4.4vh;
    left: 20.4vw;
    font-weight: 400;
  }
  #ticker_blue_layer {
    height: 3.75vw;
    width: 100vw;
    background-color: #01102b;
    font-size: 1.25vw;
    line-height: 3.33vh;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
  }
  #base {
    width: 16.6vw;
    height: 6.6vh;
    background: #bffe01;
    position: absolute;
    left: 41.6vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #partnership_bg {
    position: absolute;
    font-size: 1.9vh;
    font-weight: 400;
    line-height: 2.9vh;
    color: white;
    display: flex;
    align-items: center;
    left: 3.6vw;
    gap: 3.2vh;
    /* justify-content: center; */
  }
  #partner_runs {
    font-weight: 500;
  }

  #t1_overs_text {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: #001030;
  }
  #t1_bowler_name {
    font-size: 2.4vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: white;
    position: absolute;
    left: 59.9vw;
  }
  #t1_bowler_runs_balls {
    font-size: 2.4vh;
    font-weight: 400;
    line-height: 3.3vh;
    color: white;
    margin-left: 3.4vw;
  }
  #t1_current_balls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 75vw;
  }
  #t1_single_balls {
    height: 3.5vh;
    width: 3.5vh;
    background-color: white;
    border-radius: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.2vh;
    font-size: 0.8vh;
  }
  #t1_single_balls span {
    width: 3.5vh !important;
  }
}

#t2_toss_container {
  font-family: "Inter";
}
#t2_toss_bottom_layer {
  position: absolute;
  top: 81.2vh;
  left: 0;
  height: 7vh;
  width: 100vw;
  background-image: linear-gradient(
    85deg,
    #97897b 0%,
    #7b7166 13.02%,
    #6e665a 29.69%,
    #60574e 51.56%,
    #504a42 69.79%,
    #2f2b26 100%
  );
  font-size: 1vw;
  line-height: 2.9vh;
  font-weight: 700;
  color: #ffffff;
  font-family: "Inter";
}
#t2_toss_bg img {
  position: absolute;
  top: 68.2vh;
  left: 4.2vw;
  width: 92.7vw;
  height: 13vh;
  z-index: 0;
}
#t2_toss_teama_short_name {
  position: absolute;
  top: 71vh;
  left: 13.3vw;
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 4.5vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
}
#t2_toss_team_name {
  position: absolute;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  z-index: 1;
  text-transform: uppercase;
  top: 75.7vh;
  left: 13.3vw;
  color: #001030;
}
#t2_toss_vs_text {
  position: absolute;
  left: 48.5vw;
  top: 71.5vh;
  font-size: 2.7vw;
  font-weight: 800;
  line-height: 6.25vh;
  z-index: 2;
  text-transform: uppercase;
  color: #003125;
}
#t2_toss_teamb_short_name {
  position: absolute;
  left: 73.75vw;
  top: 71vh;
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 4.5vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
}
#t2_toss_teamb_name {
  position: absolute;
  left: 73.75vw;
  top: 75.5vh;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  z-index: 1;
  text-transform: uppercase;
  color: #001030;
}
#t2_toss_logos {
  position: absolute;
  left: 28.6vw;
  top: 43.2vh;
  gap: 26vw;
  display: inline-flex;
}

#t2_toss_logos img {
  height: 16.68vh;
  width: 16.68vh;
  border-radius: 8.3vw;
  /* border:8px solid #918171; */
  background: linear-gradient(#918171, #ece5d7, #c4b6a4);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.3vw solid #918171;
}
#t2_toss_title_bg {
  position: absolute;
  left: 36vw;
  top: 25.3vh;
}
#t2_toss_title_bg img {
  height: 11.7vh;
  width: 27.5vw;
}
#t2_toss_title_text {
  font-size: 1.6vw;
  font-weight: 800;
  line-height: 4.1vh;
  z-index: 1;
  position: absolute;
  left: 44vw;
  top: 28.9vh;
  z-index: 99;
  text-transform: uppercase;
}

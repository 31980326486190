:root {
  --white_gradient_start_color: #eaeaea;
  --white_gradient_middle_color: #fff;
  --white_gradient_end_color: #eaeaea;
  --blue_gradient_start_color: #568cfe;
  --blue_gradient_middle_color: #2050bc;
  --blue_gradient_end_color: #1e4796;
  --orange_gradient_start_color: #ff9469;
  --orange_gradient_middle_color: #e4602b;
  --orange_gradient_end_color: #d83e22;
  --blue_gradient_start_bg: #193b83;
  --blue_gradient_end_bg: rgba(13, 29, 64, 0.31);
  --title_text_black_color: #1a1717;
  --player_name_text_black_color: #001030;
  --white_text_color: #ffffff;
  --border_bottom_white_color: #fff;
}
#t3_batting_bg {
  font-family: "Inter";
}

#t3_batting_title_bg {
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--white_gradient_start_color) 0%,
    var(--white_gradient_middle_color) 53.12%,
    var(--white_gradient_end_color) 100%
  );
  height: 12.8vh;
  left: 15.6vw;
  position: absolute;
  top: 8.6vh;
  color: var(--title_text_black_color);
  display: flex;
  align-items: center;
}
#t3_batting_title_bg img {
  border-radius: 3.5vw;
  height: 7vh;
  width: 7vh;
  position: absolute;
  left: 3.5vw;
  border-radius: 7vh;
  border: 0.2vh solid #10629E;
  background-color: #001030;
}
#t3_batting_team_name {
  font-size: 4.5vh;
  font-weight: 400;
  line-height: 2vh;
  position: absolute;
  left: 8vw;
  text-transform: uppercase;
}
#t3_batting_summary_text {
  /* position: absolute; */
  /* left: 41.75vw; */
  color: var(--title_text_black_color);
  font-size: 4vh;
  font-weight: 500;
  line-height: 2vh;
  /* right: 6vw; */
}
#inning_text{
margin-top: 2.5vh;
  color: var(--title_text_black_color);
  font-size: 2.9vh;
  font-weight: 550;
  line-height: 2vh;
  top: 4vh;
  color: #d83e22;
}
#t3_batting_text{
  position: absolute;
  top: 3.8vh;
  right: 7.2vw;
  text-align: right;
}
#t3_blue_bg {
  height: 74.3vh;
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--blue_gradient_start_bg) 30.73%,
    var(--blue_gradient_end_bg) 100%
  );
  position: absolute;
  left: 15.6vw;
  top: 21.2vh;
}
#t3_batting_row {
  width: 58.3vw;
  margin-left: 5.2vw;
  margin-top: 2.9vh;
  display: flex;
}
#t3_batting_player_bg {
  height: 5.3vh;
  width: 46.5vw;
  background-image: linear-gradient(
      180deg,
      var(--white_gradient_middle_color) 39.06%,
      #bcbcbc 100%
    ),
    linear-gradient(
      180deg,
      var(--blue_gradient_start_color) 0%,
      var(--blue_gradient_middle_color) 53.48%,
      var(--blue_gradient_end_bg) 100%
    );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  color: #001030;
}
#t3_batting_player_blue_bg {
  height: 5.3vh;
  width: 46.5vw;
  background-image:linear-gradient(180deg, #568cfe 0%, #2050bc 53.48%, #1e4796 100%);
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  color: white;
}
#t3_bat_player_extras_bg{
  height: 5.3vh;
  width: 46.5vw;
  background-image:linear-gradient(180deg, #568cfe 0%, #2050bc 53.48%, #1e4796 100%);
  border-bottom: 0.1vh solid white;
  border-top: 0.1vh solid white;
  display: flex;
  align-items: center;
  color: white;
  top: 66.4vh;
  position: absolute;
}
#t3_batting_player_runs_bg {
  height: 5.3vh;
  width: 11.7vw;
  background-image: linear-gradient(
    180deg,
    var(--orange_gradient_start_color) 0%,
    var(--orange_gradient_middle_color) 48.96%,
    var(--orange_gradient_end_color) 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  text-align: right;

}
#t3_batting_runs_bg{
  height: 5.3vh;
  width: 11.7vw;
  background-image: linear-gradient(
    180deg,
    var(--orange_gradient_start_color) 0%,
    var(--orange_gradient_middle_color) 48.96%,
    var(--orange_gradient_end_color) 100%
  );
  border-bottom: 0.1vh solid white;
  border-top: 0.1vh solid white;
  display: flex;
  align-items: center;
  text-align: right;
  top: 66.4vh;
  position: absolute;
  left: 51.1vw;
}
#t3_batting_player_name {
  margin-left: 1.6vw;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
}
#t3_batting_player_out_type {
  margin-left: 16.7vw;
  position: absolute;
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
}
#t3_batting_bowler {
  margin-left: 32vw;
  position: absolute;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#t3_batting_player_runs {
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.3vh;
  color: white;
  margin-left: 3.95vw;
  text-align: right;
  display: flex;
  justify-content: right;
  width: 2vw;
}

#t3_batting_player_balls {
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  margin-top: 0.3vh;
  margin-left: 0.8vw;
}
#t3_batting_player_extras_bg {
  height: 5.3vh;
  width: 47vw;
  background-image: 
    linear-gradient(180deg, #568cfe 0%, #2050bc 53.48%, #1e4796 100%);
  
  border-bottom: 0.1vh solid white;
  border-top: 0.1vh solid white;
  display: flex;
  align-items: center;
  color: white;
  top: 66.4vh;
  position: absolute;
}
#t3_batting_overs_bg {
  height: 5.3vh;
  width: 11.7vw;
  background-image: linear-gradient(
    180deg,
    var(--orange_gradient_start_color) 0%,
    var(--orange_gradient_middle_color) 48.96%,
    var(--orange_gradient_end_color) 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  top: 66.4vh;
  position: absolute;
  left: 51vw;
  border-top: 0.1vh solid white;
}
#t3_batting_extras {
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  color: white;
  text-transform: uppercase;
  margin-left: 1.6vw;
}
#t3_batting_overs {
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  color: white;
  text-transform: uppercase;
  margin-left: 25.8vw;
}
#t3_batting_runs_wickets {
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.3vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.7vw;
}

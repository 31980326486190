#team_side_gradient_effect {
  background-repeat: no-repeat;
  /* width: 35vw; */
  position: absolute;
  top: 12vh;
  left: 11vw;
  /* height: 71.5vh; */
  font-family: "Oswald";
}
#teams_gradient_bg {
  border: 5px solid #bffe01;
  height: 80.4vh;
  width: 58.5vw;
  left: 22vw;
  margin-top: 4.8vh;
  top: 6.6vh;
  background-image: linear-gradient(
    180deg,
    #bffe01 0%,
    rgba(191, 254, 1, 0) 100%
  );
}
#teams_white_bg {
  background-color: #ffffff;
  width: 59vw;
  height: 80vh;
  z-index: 2;
  
}
#teams_row_width{
  padding: 1vw;
}
#teams_playing_text {
  background-color: #bffe01;
  width: 25vw;
  height: 11vh;
  position: absolute;
  left: 6.5vw;
  font-size: 2.3vw;
  font-weight: 600;
  font-family: "Oswald";
  line-height: 5.7vh;
  color: #001030;
  display: flex;
  align-items: center;
  padding-left: 2vw;
}
#teams_teama_short_name {
  font-size: 2.1vw;
  font-weight: 500;
  line-height: 4.8vh;
  color: #001030;
  text-transform: uppercase;
  /* position: absolute; */
  padding-top:5.5vh;
  padding-bottom:2.5vh;
}
#teams_teama_full_name {
  font-size: 1vw;
  font-weight: 400;
  padding-top:5.5vh;
  padding-bottom:2.5vh;
  line-height: 4.8vh;
  color: #001030;
}
#teams_teamb_short_name {
  font-size: 2.1vw;
  font-weight: 500;
  line-height: 4.8vh;
  padding-top:5.5vh;
  padding-bottom:2.5vh;
  color: #001030;
  text-transform: uppercase;
}
#teams_teamb_full_name {
  font-size: 1vw;
  color: #001030;
  list-style: 4.8vh;
  line-height: 4.8vh;
  padding-top:5.5vh;
  padding-bottom:2.5vh;
  text-transform: uppercase;

}
#teama_player_name {
  font-size: 1vw;
  font-weight: 500;
  line-height: 3.08vh;
  text-transform: uppercase;
  background-color: #0b1234;
  margin-top:0.5vh;
  padding-left: 1.5vh;
  color: white;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#teama_player_detail {
  background: #e50785;
  height: 5vh;
  width: 11vw;
  display: flex;
  align-items: center;
  padding-left: 1vw;
}
#teams_outer_gradient_layout {
  background-image: linear-gradient(148deg, #7DFF02 0%, rgba(124, 255, 0, 0.00) 50.87%, rgba(124, 255, 0, 0.00) 100%);
  height: 52.3vh;
  width: 24.4vw;
  transform: rotate(-20deg);
  position: absolute;
  left: 14.3vw;
  top: 27vh;
  z-index: 0;
}
#teams_inner_gradient_layout{
   background-image: linear-gradient(
    155deg,
    #bffe01 0%,
    rgba(191, 254, 1, 0) 41.68%,
    rgba(191, 254, 1, 0) 97.71%
  );
  height: 52.3vh;
  width: 24.4vw;
  transform: rotate(-20deg);
  position: absolute;
  left: 16.6vw;
  top: 28.1vh;
  z-index: 1;
}
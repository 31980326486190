#t1_player_info_container{
position: absolute;
top:3vh;
font-family: "Oswald";
}

#player_logo_border {
  border: 2px solid #bffe01;
  height: 21vh;
  width: 10.4vw;
  left: 3.5vw;
  z-index: 1;
  margin-top: 1vh;
}
#player_white_bg img{
  height: 68.8vh;
  width: 29vw;
  
  /* background-color: white; */
}
#player_logo {
  z-index: 2;
  height: 21vh;
  width: 10.4vw;
  left: 3vw;
  /* padding-left: 40px; */
}
#player_name {
  font-size: 1vw;
  width: 16.5vw;
  line-height: 2.9vh;
  font-weight: 500;
  text-transform: uppercase;
  /* padding-left: 2.6vw; */
  left: 2.6vw;
  z-index: 3;
  margin-top: 2.5vh;
  position: absolute;

}
#player_info {
  font-size: 1vw;
  line-height: 2.9vh;
  color: #737373;
  z-index: 1;
  margin-top: 6vh;
  left: 2.6vw;
  font-weight: 500;
  position: absolute;
}
#row_width {
  width: 27.16vw;
  position: absolute;
  z-index: 1;
  top: 33vh;
  padding-left: 2.6vw;

}
#player_matches {
  /* margin-top: 88px; */
  font-size: 1.4vw;
  line-height: 3.9vh;
  font-weight: 700;
  color: #031234;
}
#player_matches_text {
  font-size: 1.25vw;
  line-height: 3vh;
  font-weight: 500;
  color: #f00683;
  text-transform: uppercase;
}
#player_inngs {
  font-size: 1.4vw;
  line-height: 3.9vh;
  font-weight: 700;
  color: #0b1234;
  text-transform: uppercase;
}
#player_ings_text {
  font-size: 1.25vw;
  line-height: 3vh;
  font-weight: 500;
  color: #f00683;
  text-transform: uppercase;
}
#player_runs {
  font-size: 1.4vw;
  line-height: 3.9vh;
  font-weight: 700;
  color: #0b1234;
}
#player_runs_text {
  font-size: 1.4vw;
  line-height: 3vh;
  font-weight: 500;
  text-transform: uppercase;
  color: #f00683;
}
#player_highest_runs {
  font-size: 1.4vw;
  line-height: 3vh;
  font-weight: 700;
  color: #031234;
  margin-top: 4vh;
}
#player_highest_runs_text {
  font-size: 1.25vw;
  line-height: 3vh;
  font-weight: 500;
  color: #f00683;
  text-transform: uppercase;
}
#no_of_50 {
  font-size: 1.4vw;
  line-height: 3.9vh;
  font-weight: 700;
  color: #0b1234;
  margin-top: 4vh;
}
#text_50 {
  font-size: 1.25vw;
  line-height: 3vh;
  font-weight: 500;
  color: #f00683;
}
#no_of_100 {
  font-size: 1.4vw;
  line-height: 3.9vh;
  font-weight: 700;
  color: #0b1234;
  margin-top: 4vh;
}
#text_100 {
  font-size: 1.25vw;
  line-height: 3vh;
  font-weight: 500;
  color: #f00683;
}
#player_outer_gradient_layout {
  background-image: linear-gradient(
    148deg,
    #7dff02 0%,
    rgba(124, 255, 0, 0) 50.87%,
    rgba(124, 255, 0, 0) 100%
  );
  height: 52vh;
  width: 24.4vw;
  transform: rotate(110deg);
  position: absolute;
  left: 12.12vw;
  top: 3.12vh;
  z-index: 0;
}
#player_inner_gradient_layout {
 background-image: linear-gradient(
    155deg,
    #bffe01 0%,
    rgba(191, 254, 1, 0) 41.68%,
    rgba(191, 254, 1, 0) 97.71%
  );
  height: 52vh;
  width: 24.4vw;
  transform: rotate(110deg);
  position: absolute;
  left: 9.8vw;
  top: 5vh;
}
#next_crease_text {
  background-color: #bffe01;
  height: 10.42vh;
  width: 25.8vw;
  position: absolute;
  left: 11.45vw;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 66.7vh;
  text-transform: uppercase;
  font-size: 2.2vw;
  line-height: 5.4vh;
  font-weight: 600;
  z-index: 1;
}

#teama_logo_bg {
  position: absolute;
  left: 11.45vw;
  bottom: 8.5vh;
  z-index: 1;
  font-family: "Oswald";
}
#teama_logo_outer_gradient {
  width: 8.1vw;
  height: 16vh;
  transform: rotate(48deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12vh;
  left: 0vw;
}

#teama_logo_inner_gradient {
  width: 5.8vw;
  height: 11.6vh;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#teama_logo {
  transform: rotate(-50deg);
  height: 7vh;
  width: 3.5vw;
  border-radius: 50%;
}
#teamb_logo_bg {
  position: absolute;
  left: 80vw;
  bottom: 8.1vh;
  z-index: 1;
}
#teamb_logo_outer_gradient {
  width: 8.1vw;
  height: 16vh;
  position: absolute;
  top: -12vh;
  left: 0;
  transform: rotate(-48deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  justify-content: center;
}
#teamb_logo_inner_gradient {
  width: 5.8vw;
  position: absolute;
  /* top: 5.2vh; */
  height: 11.6vh;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  justify-content: center;
}
#teamb_logo {
  transform: rotate(50deg);
  height: 7vh;
  width: 3.5vw;
}
#green_layer {
  border-bottom: 3.75vw solid #bffe01;
  border-left: 2.08vw solid transparent;
  border-right: 2.08vw solid transparent;
  height: 0;
  width: 84.16vw;
  position: absolute;
  left: 7.86vw;
  bottom: 7.4vh;
}
#start_teama_short_name {
  font-size: 1.9vw;
  line-height: 4.5vh;
  font-weight: 500;
  color: #001030;
  position: absolute;
  top: 1.4vh;
  left: 13.41vw;
  text-transform: uppercase;
}
#start_teama_full_name {
  font-size: 1vw;
  font-weight: 400;
  line-height: 4.5vh;
  position: absolute;
  top: 1.4vh;
  left: 18vw;
  text-transform: uppercase;
}
#before_start_vs_text_bg {
  width: 12.6vw;
  height: 9.1vh;
  transform: skewX(-25deg);
  background-color: #e50785;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1.5vh;
}
#before_start_vs_text {
  font-size: 2.9vw;
  line-height: 6.6vh;
  color: #ffffff;
  font-weight: 700;
  transform: skew(25deg);
  z-index: 2;
}
#start_teamb_short_name {
  font-size: 1.9vw;
  line-height: 4.5vh;
  font-weight: 500;
  color: #001030;
  position: absolute;
  top: 1.4vh;
  left: 50.41vw;
  text-transform: uppercase;
}
#start_teamb_full_name {
  font-size: 1.2vw;
  font-weight: 400;
  position: absolute;
  left: 55vw;
  font-size: 1vw;
  top: 1.4vh;
  line-height: 4.5vh;
  text-transform: uppercase;
}
#blue_layer {
  height: 7.5vh;
  width: 100vw;
  background-color: #01102b;
  font-size: 1.25vw;
  line-height: 3.33vh;
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (max-height: 355px) {
  #before_start_vs_text_bg {
    width: 12.6vw;
    height: 9.1vh;
    transform: skewX(-25deg);
    background-color: #e50785;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.4vh;
  }
}

@media only screen and (max-width: 700px) {
  #before_start_vs_text_bg {
    width: 12.6vw;
    height: 9.1vh;
    transform: skewX(-25deg);
    background-color: #e50785;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top:-2.5vh;
  }
}
/* @media only screen and (max-height:1440px){
  #teama_logo_bg {
  position: absolute;

  bottom: 78px;
  z-index: 1;
}
#teama_logo_outer_gradient {
  width: 156px;
  height: 156px;
  transform: rotate(48deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  justify-content: center;
}

#teama_logo_inner_gradient {
  width: 112px;
  height: 112px;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#teama_logo {
  transform: rotate(-50deg);
}
#teamb_logo_bg {
  position: absolute;
  left: 1550px;
  bottom: 78px;
  z-index: 1;
}
#teamb_logo_outer_gradient {
  width: 156px;
  height: 156px;
  transform: rotate(-48deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  justify-content: center;
}
#teamb_logo_inner_gradient {
  width: 112px;
  height: 112px;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  justify-content: center;
}
#teamb_logo{
    transform: rotate(50deg);
}
#green_layer{
border-bottom: 72px solid #BFFE01;
border-left: 40px solid transparent;
border-right: 40px solid transparent;
height: 0;
width: 1616px;
position: absolute;
left: 151px;
top: -24px;
}
#start_teama_short_name{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
    color: #001030;
    text-transform: uppercase;
}
#start_teama_full_name{
    font-size: 20px;
    font-weight: 400;
    line-height: 44px;
    text-transform: uppercase;
}
#before_start_vs_text_bg{
    width: 242px;
    height: 88px;
    transform:skewX(-25deg);
    background-color:#E50785;
    position: absolute;
    top: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#before_start_vs_text{
    font-size: 56px;
    line-height: 64px;
    color: #FFFFFF;
    font-weight: 700;
    transform: skew(25deg);
    z-index: 2;
}
#start_teamb_short_name{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    color: #001030;
    text-transform: uppercase;
}
#start_teamb_full_name{
    font-size: 20px;
    font-weight: 400;
    line-height: 44px;
    text-transform: uppercase;
}
#blue_layer{
    height: 72px;
    width: 100vw;
    background-color: #01102B;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    text-transform: uppercase;
}
} */

#t3_start_white_bg {
  height: 10.3vh;
  width: 77vw;
  background-image: linear-gradient(
    180deg,
    #eaeaea 0%,
    #fff 53.12%,
    #eaeaea 100%
  );
  margin-left: 11.4vw;
  margin-top: 86.3vh;
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: relative;
}
#t3_start_teama img {
  height: 7vh;
  width: 7vh;
  margin-left: 10.8vw;
  border-radius: 7vh;
  border: 0.2vh solid #10629e;
  background-color: #10629e;
}
#t3_start_teama_name {
  font-size: 4.56vh;
  font-weight: 100;
  line-height: 2.9vh;
  color: #1a1717;
  margin-left: 1.45vw;
  text-transform: uppercase;
}
#t3_start_teamb_name {
  font-size: 4.56vh;
  font-weight: 100;
  line-height: 2.9vh;
  color: #1a1717;
  margin-left: 36.45vw;
  text-transform: uppercase;
}
#t3_start_teamb img {
  height: 7vh;
  width: 7vh;
  margin-left: 1.45vw;
  border-radius: 7vh;
  border: 0.2vh solid #10629e;
  background-color: #10629e;
}
#t3_before_text_bg {
  height: 10.3vh;
  width: 17vw;
  background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%);
  margin-top: -14vh;
  position: absolute;
  /* left: 31.45vw; */
  border-top: 0.15vh solid transparent;
  border-bottom: 0.15vh solid transparent;
  border-image: linear-gradient(
      to left,
      #204eb37d,
      #ffffff,
      #e7e7e7,
      #e7e7e7,
      #1f499f91
    )
    10;
  display: flex;
  justify-content: center;
  align-items: center;
}
#t3_before_vs_text {
  color: white;
  font-size: 5.8vh !important;
  font-weight: 500 !important;
  line-height: 2.9vh !important;
}
#t3_before_info {
  position: absolute;
  /* background-color: #10629E; */
  font-size: 2.5vh;
  font-weight: 500;
  line-height: 3.3vh;
  color: #1a1717;
  margin-left: 23.5vw;
  top: 4.4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 35.9vw;
  z-index: 1;
}
#t3_start_white_bg1 {
  height: 10.3vh;
  width: 77vw;
  background-image: linear-gradient(
    180deg,
    #eaeaea 0%,
    #fff 53.12%,
    #eaeaea 100%
  );
  margin-left: 11.4vw;
  margin-top: 86.3vh;
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
#before_start_teama {
  margin-left: 12vh;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  gap: 1.5vh;
  font-size: 4.56vh;
  font-weight: 400;
  line-height: 5.4vh;
  color: #1a1717;
  text-transform: uppercase;
}
#before_start_teamb {
  margin-right: 12vh;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 1.5vh;
  font-size: 4.46vh;
  font-weight: 400;
  line-height: 4.4vh;
  color: #1a1717;
  text-transform: uppercase;
  
}
#before_start_text {
  position: absolute;
  font-size: 2.5vh;
  font-weight: 500;
  line-height: 3.3vh;
  color: #1a1717;
  text-align: center;
  margin-top: 2.5vh;
  text-transform: uppercase;
}
#t3_teama_start_logo {
  height: 7vh;
  width: 7vh;
  border-radius: 7vh;
  border: 0.2vh solid #10629e;
  background-color: #10629e;
}
#grid {
  display: grid;
  grid-template-columns: 50% 50%;
  bottom: 0;
  /* position: absolute; */
}
@media screen and (min-width: 997px) and (max-width: 1390px) {
  #before_start_teama {
    margin-left: 5vh;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    gap: 1.5vh;
    font-size: 4.56vh;
    font-weight: 400;
    line-height: 5.4vh;
    color: #1a1717;
    text-transform: uppercase;
  }
  #before_start_teamb {
    margin-right: 6vh;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    gap: 1.5vh;
    font-size: 4.46vh;
    font-weight: 400;
    line-height: 4.4vh;
    color: #1a1717;
    text-transform: uppercase;
    
  }
  #before_start_text {
    position: absolute;
    font-size: 2vh;
    font-weight: 500;
    line-height: 3.3vh;
    color: #1a1717;
    text-align: center;
    margin-top: 2.5vh;
    text-transform: uppercase;
  }
}

:root {
  --white_gradient_start_color: #eaeaea;
  --white_gradient_middle_color: #fff;
  --white_gradient_end_color: #eaeaea;
  --blue_gradient_start_color: #568cfe;
  --blue_gradient_middle_color: #2050bc;
  --blue_gradient_end_color: #1e4796;
  --orange_gradient_start_color: #ff9469;
  --orange_gradient_middle_color: #e4602b;
  --orange_gradient_end_color: #d83e22;
  --blue_gradient_start_bg: #193b83;
  --blue_gradient_end_bg: rgba(13, 29, 64, 0.31);

  --title_text_black_color: #1a1717;
  --player_name_text_black_color: #001030;
  --white_text_color: #ffffff;

  --border_bottom_white_color: #fff;
}
#t3_teams_bg {
  font-family: "Inter";
}

#t3_teams_title_bg {
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--white_gradient_start_color) 0%,
    var(--white_gradient_middle_color) 53.12%,
    var(--white_gradient_end_color) 100%
  );
  height: 12.8vh;
  left: 15.6vw;
  text-align: center;
  position: absolute;
  top: 8.6vh;
  color: var(--title_text_black_color);
  display: flex;
  align-items: center;
  justify-content: center;
}
#t3_teams_title_bg img {
  height: 7vh;
  width: 7vh;
  border-radius: 7vh;
  /* margin-left: 4.5vw; */
}
#t3_teams_teama_name {
  font-size: 4.5vh;
  font-weight: 400;
  line-height: 2.9vh;
  color: #1a1717;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-left: 2.45vh;
  gap: 2vh;
  position: absolute;
  left: 0;
  margin-left: 11vh;
}
#t3_teams_text {
  /* width: 41.5vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.5vh;
  font-weight: 450;
  text-align: center;
  line-height: 2.9vh;
  color: #1a1717;
}
#t3_teams_teamb_name {
  font-size: 4.5vh;
  font-weight: 400;
  line-height: 2.9vh;
  color: #1a1717;
  display: flex;
  align-items: center;
  right: 0;
  position: absolute;
  margin-right: 11vh;
  gap: 2vh;
  text-transform: uppercase;
}
#t3_teams_teamb_img img {
  /* margin-left: 1.45vw !important; */
  /* margin-right: 3.5vw; */
}
#t3_teams_blue_bg {
  height: 74.3vh;
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--blue_gradient_start_bg) 30.73%,
    var(--blue_gradient_end_bg) 100%
  );
  position: absolute;
  left: 15.6vw;
  top: 21.2vh;
}
#t3_teams_row {
  margin-left: 5.2vw;
  margin-top: 3.3vh;
  width: 58.3vw !important;
}
#t3_player_type_bg {
  display: grid;
  grid-template-columns: 58% 42%;
}
#t3_teams_player_name_bg {
  width: 16.9vw;
  height: 5.3vh;
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
}
#t3_teams_player_type_bg {
  width: 11.7vw;
  height: 5.3vh;
  background-image: linear-gradient(
    180deg,
    #ff9469 0%,
    #e4602b 48.96%,
    #d83e22 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
}
#t3_teamsb_player_name_bg {
  width: 15.9vw;
  height: 5.3vh;
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  border-bottom: 0.1vh solid white;
  margin-left: 2.9vw;
  display: flex;
  align-items: center;
}
#t3_teamsb_player_type_bg {
  width: 11.7vw;
  height: 5.3vh;
  background-image: linear-gradient(
    180deg,
    #ff9469 0%,
    #e4602b 48.96%,
    #d83e22 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
}
#t3_teams_player_name {
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  color: white;
  margin-left: 1.7vw;
  text-transform: uppercase;
}
#t3_teams_player_type {
  font-size: 1.9vh;
  font-weight: 500;
  line-height: 2.5vh;
  color: white;
  margin-left: 1.25vw;
  text-transform: uppercase;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent !important;
}

code {
  font-family: "poppins";
}
#webpack-dev-server-client-overlay {
  display: none !important;
}


#center_bg {
  position: absolute;
  background-color: aqua;
  height: 25vh;
  width: 25vh;
  margin-top: 10vh;
  margin-left: 10vh;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}
#center_text_bg1 {
  height: 20vh;
  width: 20vh;
  /* margin-top: 10vh; */
  /* margin-left: 10vh; */
  background-color: yellow;
  font-size: 1.6vh;
  font-weight: 500;
  line-height: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#t2_match_font {
  font-family: "Inter";
}
#t2_match_title_bg {
  top: 3.9vh;
  position: absolute;
  left: 9.4vw;
  z-index: 1;
}
#t2_match_title_bg img {
  height: 14.5vh;
  width: 81.1vw;
}
#t2_match_teama_logo {
  position: absolute;
  top: 5.6vh;
  left: 14.8vw;
  z-index: 1;
}
#t2_match_teama_logo img {
  height: 3.5vw;
  width: 3.5vw;
  position: absolute;
  top: 1.6vh;
  border: 0.18vw solid #918171;
  border-radius: 3.5vw;
}
#t2_match_teama_short_name {
  position: absolute;
  top: 7vh;
  left: 19.6vw;
  font-size: 1.87vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_match_teama_name {
  position: absolute;
  top: 11.5vh;
  left: 19.6vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  z-index: 1;
  color: #001030;
}
#t2_match_playing_text {
  position: absolute;
  left: 43vw;
  top: 7.3vh;
  font-size: 1.6vw;
  font-weight: 800;
  line-height: 4.1vh;
  z-index: 1;
  text-transform: uppercase;
}
#t2_match_teamb_logo {
  position: absolute;
  left: 66vw;
  top: 5.6vh;
}
#t2_match_teamb_logo img {
  position: absolute;
  height: 3.5vw;
  width: 3.5vw;
  z-index: 1;
  top: 1.6vh;
  border: 0.18vw solid #918171;
  border-radius: 3.5vw;
}
#t2_match_teamb_short_name {
  position: absolute;
  left: 70.9vw;
  top: 7vh;
  font-size: 1.87vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_match_teamb_name {
  position: absolute;
  left: 70.9vw;
  top: 11.5vh;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_white_match_bg {
  position: absolute;
  left: 16vw;
  top: 10.4vh;
}
#t2_white_match_bg img {
  height: 77vh;
  width: 67vw;
  z-index: 0;
}
#t2_match_bottom_line {
  position: absolute;
  left: 13vw;
  top: 83.8vh;
  height: 6.25vh;
  width: 73.2vw;
  background-image: linear-gradient(180deg, #fff 0%, #d6d6d6 100%);
  border-radius: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.45vw;
  font-weight: 700;
  line-height: 1.25vh;
  color: #001030;
  text-transform: uppercase;
}
#t2_match_row {
  position: absolute;
  left: 5.7vw;
  top: 8.9vh;
  height: 7vh;
  width: 56vw;
  background-image: linear-gradient(
    to right,
    #97897b,
    #7b7166,
    #6e665a,
    #60574e,
    #504a42,
    #2f2b26
  );
  border-radius: 2.4vw;
}
#t2_match_teama_short_spec_name {
  position: absolute;
  left: 1.6vw;
  top: 1.25vh;
  font-size: 1.85vw;
  font-weight: 700;
  line-height: 4.5vh;
  color: #ffffff;
  text-transform: uppercase;
}
#t2_match_teama_spec_name {
  position: absolute;
  left: 7vw;
  top: 2vh;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  color: #ffffff;
  text-transform: uppercase;
}
#t2_match_teama_runs_balls {
  position: absolute;
  left: 41.5vw;
  top: 1.25vh;
  font-size: 1.85vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #ffffff;
}
#t2_match_teama_overs {
  position: absolute;
  left: 49.375vw;
  top: 2.9vh;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  color: #ffffff;
}
#t2_match_teamb_row {
  position: absolute;
  left: 5.7vw;
  top: 41.2vh;
  height: 7vh;
  width: 56vw;
  background-image: linear-gradient(
    to right,
    #97897b,
    #7b7166,
    #6e665a,
    #60574e,
    #504a42,
    #2f2b26
  );
  border-radius: 2.4vw;
}
#t2_match_player_type_bg {
  /* position: absolute; */
  border-radius: 2vw;
  margin-top: 0.4vh;
  width: 26.8vw;
  height: 5.4vh;
  background-image: linear-gradient(#002513, #003623);
  display: flex;
  align-items: center;
}
#t2_match_player_type {
  font-size: 2.4vh;
  font-weight: 600;
  line-height: 3.3vh;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 2;
  margin-left: 20.9vw;
  justify-content: center;
  text-align: right !important;
}
#t2_match_player_name_bg {
  position: absolute;
  width: 18.125vw;
  height: 5.4vh;
  background-image: linear-gradient(#ffffff, #d6d6d6);
  display: flex;
  align-items: center;
  padding-left: 1.25vw;
  border-radius: 1.25vw;
  font-size: 1vw;
  font-weight: 600;
  line-height: 2.9vh;
  color: #001030;
  text-transform: uppercase;
}
#t2_match_teama_names {
  margin-top: 7.5vh;
}
#t2_match_teamb_names {
  padding-left: 1vw;
  margin-top: 7.5vh;
}

#t2_match_player_balls {
  font-size: 1.6vh;
  font-weight: 400;
  line-height: 2.4vh;
  position: absolute;
  left: 25.5vw;
  margin-top: 0.5vh;
  text-align: right;
}
#t2_match_player_bowling_balls{
  font-size: 1.6vh;
  font-weight: 400;
  line-height: 2.4vh;
  position: absolute;
  left: 103.5vh;
  margin-top: 0.5vh;

}
#t2_match_player_runs {
  text-align: right !important;
}
#t2_match_player_types{
    /* position: absolute; */
    border-radius: 2vw;
    margin-top: 0.4vh;
    width: 26.8vw;
    height: 5.4vh;
    background-image: linear-gradient(#002513, #003623);
    display: flex;
    align-items: center;
}
#t2_match_player_bowling_overs{
  font-size: 1.6vh;
  font-weight: 400;
  line-height: 2.4vh;
  position: absolute;
  margin-left:9vh;
  margin-top: 0.5vh;

}
#t2_batting_font {
  font-family: "Inter";
  height: 100vh;
}
#t2_batting_title_bg {
  top: 3.9vh;
  position: absolute;
  left: 9.4vw;
  z-index: 1;
}
#t2_batting_title_bg img {
  height: 14.5vh;
  width: 80.1vw;

}
#t2_batting_teama_logo {
  position: absolute;
  top: 5.6vh;
  left: 14.8vw;
  z-index: 1;
}
#t2_batting_teama_logo img {
  height: 3.5vw;
  width: 3.5vw;
  position: absolute;
  top: 1vh;
  border-radius: 3.5vw;

}
#t2_batting_teama_short_name {
  position: absolute;
  top: 7vh;
  left: 19.6vw;
  font-size: 1.87vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_batting_teama_name {
  position: absolute;
  top: 11.5vh;
  left: 19.6vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  z-index: 1;
  color: #001030;
}
#t2_batting_playing_text {
  position: absolute;
  left: 43vw;
  top: 7.3vh;
  font-size: 1.6vw;
  font-weight: 800;
  line-height: 4.1vh;
  z-index: 1;
  text-transform: uppercase;
}
#t2_batting_batting_summary {
  position: absolute;
  left: 64.7vw;
  top: 8.1vh;
  font-size: 1.6vw;
  font-weight: 800;
  color: #001030;
  z-index: 1;
  text-transform: uppercase;
}
#t2_white_batting_bg {
  position: absolute;
  left: 16vw;
  top: 10.4vh;
}
#t2_white_batting_bg img {
  height: 77vh;
  width: 67vw;
  z-index: 0;
}
#t2_batting_bottom_line {
  position: absolute;
  left: 13vw;
  top: 83.8vh;
  height: 6.25vh;
  width: 73.2vw;
  background-image: linear-gradient(
    180deg,
    #918171 0%,
    #ece5d7 45.98%,
    #f1e7d6 56.55%,
    #c4b6a4 92.47%
  );
  border-radius: 1.4vw;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  line-height: 2.8vh;
  color: #68696d;
  text-transform: uppercase;
}
#t2_batting_row {
  position: absolute;
  left: 2.7vw;
  top: 8.9vh;
}

#t2_batting_player_type_bg {
  /* position: absolute; */
  border-radius: 1.25vw;
  margin-top: 0.4vh;
  width: 62vw;
  height: 5.4vh;
  background-image: linear-gradient(#002513, #003623);
  display: flex;
  align-items: center;
}
#t2_batting_player_type {
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 2;
  margin-left: 18.9vw;
}
.t2_batting_player_name_bg {
  position: absolute;
  width: 53.125vw;
  height: 5.4vh;
  background-image: linear-gradient(#ffffff, #d6d6d6);
  display: flex;
  align-items: center;
  padding-left: 1.25vw;
  border-radius: 1.25vw;
  font-size: 1vw;
  font-weight: 600;
  line-height: 2.9vh;
  color: #001030;
  text-transform: uppercase;
}
#t2_batting_catcher_name {
  position: absolute;
  left: 23.7vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  color: #000;
  text-transform: capitalize;
}
#t2_batting_bowler_name {
  position: absolute;
  left: 37.3vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  color: #000;
  text-transform: capitalize;
}
#t2_batting_runs {
  position: absolute;
  left: 55.15vw;
  font-size: 1.25vw;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 3.33vh;
}
#t2_batting_balls {
  position: absolute;
  left: 58vw;
  font-size: 0.8vw;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 2.5vh;
  top: 1.6vh;
}
.t2_batting_not_out_player_name_bg {
  position: absolute;
  width: 53.125vw;
  height: 5.4vh;
  background-image: linear-gradient(#918171, #ece5d7, #f1e7d6, #c4b6a4);
  display: flex;
  align-items: center;
  padding-left: 1.25vw;
  border-radius: 1.25vw;
  font-size: 1vw;
  font-weight: 600;
  line-height: 2.9vh;
  color: #001030;
  text-transform: uppercase;
}
#t2_batting_extras {
  position: absolute;
  left: 8vw;
}
#t2_batting_overs {
  position: absolute;
  left: 39.5vw;
}
#t2_batting_extras_overs_bg {
  left: 13vw;
  top: 83.8vh;
  height: 6.25vh;
  width: 55.7vw;
  background-image: linear-gradient(180deg, #fff 0%, #d6d6d6 100%);
  border-radius: 1.4vw;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  line-height: 2.8vh;
  color: #68696d;
  text-transform: uppercase;
}
#t2_batting_result {
  position: absolute;
  left: 59.2vw;
  font-size: 1.8vw;
  font-weight: 800;
  line-height: 4.5vh;
  color: #003125;
}

#t2_container_bg {
  position: relative;
  font-family: "Inter";
}

#t2_left_bg {
  height: 12.8vh;
  width: 36vw;
  position: absolute;
  top: 86.8vh;
  transform: rotate(180deg);
  background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 10% 50%);
  z-index: 1;
}
#t2_left_contents {
  transform: rotate(180deg);
  height: 12.8vh;
  position: relative;
  display: flex;
  /* position: relative; */
}
#t2_left_logo img {
  height: 9.9vh;
  width: 9.9vh;
  border: 0.18vw solid #918171;
  border-radius: 9.9vh;
  /* position: absolute; */
  /* top: 0vh; */
  z-index: 1;
  margin-top: 1.5vh;
  margin-left: 2.5vw;
  /* background-color: #151412; */
}
#t2_left_border_bg {
  height: 12.8vh;
  width: 36vw;
  position: absolute;
  top: 86.8vh;
  transform: rotate(180deg);
  background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
  background-image: linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    ),
    linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    );
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 10% 50%);
  z-index: 0;
  left: 0.625vw;
}
#t2_arrow {
  margin-left: 1vw;
  margin-top: 2.8vh;
}
#t2_striker_arrow {
  width: 0;
  height: 0;
  border-top: 1vh solid transparent;
  border-left: 1.3vh solid #958575;
  border-bottom: 1vh solid transparent;
  margin-top: 0.4vh;
}
#t2_striker_name {
  font-size: 2.5vh;
  font-weight: 600;
  line-height: 3.7vh;
  color: #33302c;
  position: absolute;
  top: 2.5vh;
  margin-left: 1.5vw;
  text-transform: uppercase;
  /* background-color: palevioletred; */
}
#t2_striker_runs {
  font-size: 2.5vh;
  font-weight: 700;
  line-height: 3.3vh;
  top: 0.14vh;
  left: 13vw;
  position: absolute;
  /* background-color: palevioletred; */
}
#t2_striker_balls {
  font-size: 1.8vh;
  left: 15.2vw;
  top: 0vh;
  position: absolute;
}
#t2_non_striker_runs {
  font-size: 2.5vh;
  font-weight: 700;
  line-height: 3.3vh;
  left: 13vw;
  position: absolute;
  top: 0.2vh;
  /* background-color: palegreen; */
}
#t2_non_striker_balls {
  font-size: 1.8vh;
  left: 15.2vw;
  top: 0vh;
  position: absolute;
}
#t2_non_striker_name {
  font-size: 2.5vh;
  font-weight: 600;
  line-height: 3.7vh;
  color: #33302c;
  position: absolute;
  top: 7.2vh;
  /* background-color: palegreen; */
  margin-left: 1.5vw;
  text-transform: uppercase;
}
#t2_center_bg {
  height: 12.8vh;
  width: 42vw;
  position: absolute;
  top: 86.8vh;
  transform: rotate(180deg);
  background-image: linear-gradient(
    180deg,
    #918171 0%,
    #ece5d7 45.98%,
    #f1e7d6 56.55%,
    #c4b6a4 92.47%
  );
  clip-path: polygon(25% 0%, 75% 0%, 91% 50%, 75% 100%, 25% 100%, 10% 50%);
  z-index: 1;
  left: 29vw;
}

#t2_center_contents {
  transform: rotate(180deg);
  height: 12.8vh;
  position: relative;
  display: flex;
}
#t2_active_team_name {
  font-size: 3.3vh;
  font-weight: 600;
  line-height: 4.5vh;
  color: #33302c;
  position: absolute;
  top: 2.5vh;
  /* margin-left: 15vw; */
  left: 13vw;
  text-transform: uppercase;
}
#t2_vs_text {
  position: absolute;
  left: 13.3vw;
  top: 6.7vh;
  font-size: 1.2vh;
  font-weight: 800;
  line-height: 4.1vh;
  color: rgba(0, 16, 48, 0.5);
}
#t2_chasing_team {
  position: absolute;
  left: 15vw;
  top: 6.8vh;
  font-size: 2vh;
  font-weight: 500;
  line-height: 4.1vh;
  color: rgba(0, 16, 48, 0.5);
  text-transform: uppercase;
}
#t2_1st_st_line {
  position: absolute;
  left: 20vw;
  top: 2.2vh;
  /* color: #958575; */
  background-image: linear-gradient(#aea393, #c2b8a9, #dbcfbd);
  height: 5vh;
  width: 0.2vw;
}
#t2_team_runs_wickets_bg {
  position: absolute;
  left: 37vh;
  height: 5.3vh;
  width: 9.5vw;
  /* background-color: #33302c; */
  top: 2vh;
  color: #151412;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.5vh;
  line-height: 5.3vh;
  font-weight: 800;
}
#t2_2nd_st_line {
  position: absolute;
  left: 31vw;
  top: 2.2vh;
  background-image: linear-gradient(#aea393, #c2b8a9, #dbcfbd);
  height: 5vh;
  width: 0.2vw;
}
#t2_run_rate_text {
  position: absolute;
  left: 4.8vw;
  top: 8.7vh;
  color: black;
  text-transform: uppercase;
  font-size: 2.1vh;
  font-weight: 600;
  line-height: 2.9vh;
  width: 42vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: palegoldenrod; */
}
#t2_run_rate{
    margin-left: 0.4vw;
}

#t2_ov_text {
  position: absolute;
  left: 32.2vw;
  top: 3vh;
  text-transform: uppercase;
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.7vh;
  color: #33302c;
}
#t2_curr_overs {
  position: absolute;
  left: 35.3vw;
  top: 3vh;
  text-transform: uppercase;
  font-size: 2.9vh;
  font-weight: 700;
  line-height: 3.7vh;
}
#t2_right_bg {
  height: 12.8vh;
  width: 36vw;
  position: absolute;
  top: 86.8vh;
  transform: rotate(360deg);
  background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 10% 50%);
  z-index: 1;
  right: 0;
}
#t2_right_border_bg {
  height: 12.8vh;
  width: 36vw;
  position: absolute;
  top: 86.8vh;
  transform: rotate(360deg);
  background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
  background-image: linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    ),
    linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    );
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 10% 50%);
  z-index: 0;
  right: 0;
  margin-right: 0.625vw;

  /* left: 0.625vw; */
}
#t2_bowler_name {
  position: absolute;
  left: 10vw;
  color: black;
  top: 2.5vh;
  font-size: 2.5vh;
  font-weight: 600;
  line-height: 3.7vh;
  text-transform: uppercase;
}
#t2_bowler_runs_balls {
  position: absolute;
  width: 4.2vw;
  display: flex;
  justify-content: center;
  left: 12vw;
  font-size: 2.5vh;
  font-weight: 700;
  line-height: 3.3vh;
  top: 0vh;
}
#t2_bowler_st_line {
  position: absolute;
  left: 26.7vw;
  background-image: linear-gradient(#ece5d7, #a39585);
  height: 3.4vh;
  width: 0.1vw;
  top: 2.3vh;
}
#t2_bowler_overs {
  position: absolute;
  left: 27.5vw;
  top: 2.5vh;
  font-size: 2.1vh;
  font-weight: 600;
  line-height: 3.3vh;
}
#t2_teamb_logo {
  position: absolute;
  left: 30vw;
  top: 1.5vh;
}
#t2_teamb_logo img {
  height: 9.9vh;
  width: 9.9vh;
  border-radius: 9.9vh;
  border: 0.18vw solid #918171;
}
#t2_curr_balls{
    /* left: 20vw; */
    top: 7vh;
}
#t2_single_balls{
    border-radius: 4.5vh;
}
@media only screen and (min-width: 1920px) and (min-height: 1080px) {
  #t2_container_bg {
    position: relative;
  }

  #t2_left_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 1;
  }
  #t2_left_border_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    background-image: linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      ),
      linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      );
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 0;
    left: 0.625vw;
  }
  #t2_center_bg {
    height: 12.8vh;
    width: 51.6vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    );
    clip-path: polygon(25% 0%, 75% 0%, 83% 50%, 75% 100%, 25% 100%, 17% 50%);
    z-index: 1;
    left: 24.2vw;
  }
  #t2_right_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(360deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 1;
    right: 0;
  }
  #t2_right_border_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(360deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    background-image: linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      ),
      linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      );
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 0;
    right: 0;
    margin-right: 0.625vw;

    /* left: 0.625vw; */
  }
}
@media only screen and (min-height: 320px) and (max-height: 420px) {
  #t2_container_bg {
    position: relative;
  }

  #t2_left_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 1;
  }
  #t2_left_border_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    background-image: linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      ),
      linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      );
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 0;
    left: 0.625vw;
  }
  #t2_center_bg {
    height: 12.8vh;
    width: 51.6vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    );
    clip-path: polygon(25% 0%, 75% 0%, 83% 50%, 75% 100%, 25% 100%, 17% 50%);
    z-index: 1;
    left: 24.2vw;
  }
  #t2_right_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(360deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 1;
    right: 0;
  }
  #t2_right_border_bg {
    height: 12.8vh;
    width: 37.8vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(360deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    background-image: linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      ),
      linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      );
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 14% 50%);
    z-index: 0;
    right: 0;
    margin-right: 0.625vw;

    /* left: 0.625vw; */
  }
  #t2_single_balls{
    font-size: 1vh !important;
    
  }
}
@media only screen and (min-height: 390px) and (max-height: 520px) {
  #t2_container_bg {
    position: relative;
  }

  #t2_left_bg {
    height: 12.8vh;
    width: 38.6vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 16% 50%);
    z-index: 1;
  }
  #t2_left_border_bg {
    height: 12.8vh;
    width: 38.6vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    background-image: linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      ),
      linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      );
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 16% 50%);
    z-index: 0;
    left: 0.625vw;
  }
  #t2_center_bg {
    height: 12.8vh;
    width: 53.4vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(180deg);
    background-image: linear-gradient(
      180deg,
      #918171 0%,
      #ece5d7 45.98%,
      #f1e7d6 56.55%,
      #c4b6a4 92.47%
    );
    clip-path: polygon(25% 0%, 75% 0%, 82% 50%, 75% 100%, 25% 100%, 18% 50%);
    z-index: 1;
    left: 23.3vw;
  }
  #t2_right_bg {
    height: 12.8vh;
    width: 38.6vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(360deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 16% 50%);
    z-index: 1;
    right: 0;
  }
  #t2_right_border_bg {
    height: 12.8vh;
    width: 38.6vw;
    position: absolute;
    top: 86.8vh;
    transform: rotate(360deg);
    background-image: linear-gradient(180deg, #fff 41.53%, #d6d6d6 119.35%);
    background-image: linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      ),
      linear-gradient(
        180deg,
        #918171 0%,
        #ece5d7 45.98%,
        #f1e7d6 56.55%,
        #c4b6a4 92.47%
      );
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 16% 50%);
    z-index: 0;
    right: 0;
    margin-right: 0.625vw;

    /* left: 0.625vw; */
  }
}

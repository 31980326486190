#t3_toss_bg {
  background-image: linear-gradient(
    0deg,
    rgba(2, 4, 7, 0.8) 0%,
    rgba(2, 4, 7, 0.8) 100%
  );
  height: 100vh;
  width: 100vw;
  font-family: "Inter";
  display: flex;
  justify-content: center;
}
#t3_toss_text_bg {
  height: 10.3vh;
  width: 17vw;
  background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%);
  margin-top: 16.4vh;
  position: absolute;
  left: 41.45vw;
  border-top: 0.15vh solid transparent;
  border-bottom: 0.15vh solid transparent;
  border-image: linear-gradient(
      to left,
      #204eb37d,
      #ffffff,
      #e7e7e7,
      #e7e7e7,
      #1f499f91
    )
    10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 5.7vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#t3_toss_match_start {
  font-size: 2.5vh;
  font-weight: 450;
  /* margin-left: 30.5vw; */
  /* left:36%; */
  margin-top: 28.2vh;
  line-height: 3.3vh;
  position: absolute;
  text-align: center;
  color: white;
  text-transform: uppercase;
}
#t3_toss_teama {
  position: absolute;
  left: 22.6vw;
  top: 36.5vh;
  color: white;
  text-transform: uppercase;
}
#t3_toss_teama img {
  height: 23.6vh;
  width: 23.6vh;
  border-radius: 23.6vh;
  border: 0.2vh solid #10629e;
  background-color: #001030;
}
#t3_toss_teama_name {
  font-weight: 400;
  font-size: 6.2vh;
  color: white;
  line-height: 2.9vh;
  width: 11.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.7vh;
}
#t3_toss_teama_fullname {
  margin-top: 3vh;
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.7vh;
}
#t3_toss_vs_text {
  left: 43.2vw;
  font-size: 19.5vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  top: 50.2vh;
  position: absolute;
}
#t3_toss_teamb {
  position: absolute;
  left: 65.6vw;
  top: 36.5vh;
  color: white;
  text-transform: uppercase;
}
#t3_toss_teamb img {
  height: 23.6vh;
  width: 23.6vh;
  border-radius: 23.6vh;
  border: 0.2vh solid #10629e;
  background-color: #001030;
}
#t3_toss_teamb_name {
  font-weight: 400;
  font-size: 6.2vh;
  color: white;
  line-height: 2.9vh;
  width: 11.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.7vh;
}
#t3_toss_teamb_fullname {
  margin-top: 3vh;
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.7vh;
}
#t3_toss_result_bg {
  position: absolute;
  height: 10.3vh;
  width: 100vw;
  background-image: linear-gradient(
      180deg,
      #eaeaea 0%,
      #fff 53.12%,
      #eaeaea 100%
    ),
    linear-gradient(180deg, #2050bc 0%, #1e4796 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 79.7vh;
  font-size: 4.1vh;
  font-weight: 450;
  line-height: 3.3vh;
  color: #001030;
  text-transform: uppercase;
}

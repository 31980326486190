#t2_teams_font {
  font-family: "Inter";
}
#t2_teams_title_bg {
  top: 3.9vh;
  position: absolute;
  left: 9.4vw;
  z-index: 1;
}
#t2_teams_title_bg img {
  height: 14.5vh;
  width: 81.1vw;
}
#t2_teams_teama_logo {
  position: absolute;
  top: 5.6vh;
  left: 14.8vw;
  z-index: 1;
}
#t2_teams_teama_logo img {
  height: 7vh;
  width: 7vh;
  position: absolute;
  top: 1.6vh;
  border: 0.18vw solid #918171;
  border-radius: 3.5vw;
}
#t2_teams_teama_short_name {
  position: absolute;
  top: 7vh;
  left: 19.6vw;
  font-size: 1.87vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_teams_teama_name {
  position: absolute;
  top: 11.5vh;
  left: 19.6vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  z-index: 1;
  color: #001030;
}
#t2_teams_playing_text {
  position: absolute;
  left: 43vw;
  top: 7.3vh;
  font-size: 1.6vw;
  font-weight: 800;
  line-height: 4.1vh;
  z-index: 1;
  text-transform: uppercase;
}
#t2_teams_teamb_logo {
  position: absolute;
  left: 66vw;
  top: 5.6vh;
}
#t2_teams_teamb_logo img {
  position: absolute;
  height: 7vh;
  width: 7vh;
  z-index: 1;
  top: 1.6vh;
  border: 0.18vw solid #918171;
  border-radius: 3.5vw;
}
#t2_teams_teamb_short_name {
  position: absolute;
  left: 70.9vw;
  top: 7vh;
  font-size: 1.87vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_teams_teamb_name {
  position: absolute;
  left: 70.9vw;
  top: 11.5vh;
  font-size: 1vw;
  font-weight: 7500;
  line-height: 2.9vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_white_teams_bg {
  position: absolute;
  left: 16vw;
  top: 10.4vh;
}
#t2_white_teams_bg img {
  height: 77vh;
  width: 67vw;
  z-index: 0;
}
#t2_teams_bottom_line {
  position: absolute;
  left: 13vw;
  top: 83.8vh;
  height: 5vh;
  width: 73.2vw;
  background-image: linear-gradient(180deg, #fff 0%, #d6d6d6 100%);
  border-radius: 1.4vw;
  margin: 0;
  padding: 0;
}
#t2_teams_row {
  position: absolute;
  left: 5.7vw;
  top: 8.9vh;
}

#t2_teams_player_type_bg {
  /* position: absolute; */
  border-radius: 1.25vw;
  margin-top: 0.4vh;
  width: 26.8vw;
  height: 5.4vh;
  background-image: linear-gradient(#002513, #003623);
  display: flex;
  align-items: center;
}
#t2_teams_player_type {
  font-size: 0.9vw;
  font-weight: 600;
  line-height: 2.9vh;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-left: 18.9vw;
}
#t2_teams_player_name_bg {
  position: absolute;
  width: 18.125vw;
  height: 5.4vh;
  background-image: linear-gradient(#ffffff, #d6d6d6);
  display: flex;
  align-items: center;
  padding-left: 1.25vw;
  border-radius: 1.25vw;
  font-size: 1vw;
  font-weight: 600;
  line-height: 2.9vh;
  color: #001030;
  text-transform: uppercase;
}
#t2_teams_teamb_names{
    padding-left: 1vw;
}
#t3_bowling_bg {
  font-family: "Inter";
}
#t3_bowling_title_bg {
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--white_gradient_start_color) 0%,
    var(--white_gradient_middle_color) 53.12%,
    var(--white_gradient_end_color) 100%
  );
  height: 12.8vh;
  left: 15.6vw;
  position: absolute;
  top: 8.6vh;
  color: var(--title_text_black_color);
  display: flex;
  align-items: center;
}
#t3_bowling_title_bg img {
  border-radius: 3.5vw;
  height: 7vh;
  width: 7vh;
  position: absolute;
  left: 4.8vw;
  border-radius: 7vh;
  border: 0.2vh solid #10629e;
  background-color: #10629e;
}
#t3_bowling_team_name {
  font-size: 4.5vh;
  font-weight: 400;
  line-height: 2vh;
  position: absolute;
  left: 9.8vw;
  text-transform: uppercase;
}
#t3_bowling_summary_text {
  /* position: absolute; */
  /* left: 41.75vw; */
  color: var(--title_text_black_color);
  font-size: 4vh;
  font-weight: 500;
  line-height: 2vh;
  /* right: 6vw; */
}
#inning_text {
  margin-top: 2.5vh;
  color: var(--title_text_black_color);
  font-size: 2.9vh;
  font-weight: 550;
  line-height: 2vh;
  top: 4vh;
  color: #d83e22;
}
#t3_bowling_text {
  position: absolute;
  top: 3.8vh;
  right: 5.2vw;
  text-align: right;
}
#t3_bowling_blue_bg {
  height: 74.3vh;
  width: 68.75vw;
  background-image: linear-gradient(
    180deg,
    var(--blue_gradient_start_bg) 30.73%,
    var(--blue_gradient_end_bg) 100%
  );
  position: absolute;
  left: 15.6vw;
  top: 21.2vh;
}
#t3_bowling_details_title_bg {
  height: 5.3vh;
  width: 58.3vw;
  background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%),
    linear-gradient(180deg, #568cfe 0%, #2050bc 53.48%, #1e4796 100%);
  margin-left: 5.2vw;
  margin-right: 5.2vw;
  margin-top: 2.9vh;
  border-bottom: 0.1vh solid white;
}
#t3_bowling_overs_text {
  position: absolute;
  left: 22vw;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  margin-top: 1.2vh;
  text-transform: uppercase;
}
#t3_bowling_dots_text {
  position: absolute;
  left: 29.7vw;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  margin-top: 1.2vh;
  text-transform: uppercase;
}
#t3_bowling_runs_text {
  position: absolute;
  left: 36.8vw;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  margin-top: 1.2vh;
  text-transform: uppercase;
}
#t3_bowling_wickets_text {
  position: absolute;
  left: 44.1vw;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  margin-top: 1.2vh;
  text-transform: uppercase;
}
#t3_bowling_economy_text {
  position: absolute;
  left: 52.8vw;
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  margin-top: 1.2vh;
  text-transform: uppercase;
}
#t3_bowling_players_bg {
  width: 58.3vw;
  height: 5.3vh;

  margin-left: 5.2vw;
  display: flex;
}
#t3_bowling_player_name_bg {
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  width: 15.5vw;
  height: 5.3vh;
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: nowrap;

 
  /* justify-content: center !important; */
}
#t3_bowling_player_name {
  margin-left: 1.6vw;
  font-size: 2vh;
  line-height: 2.9vh;
  font-weight: 500 !important;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;word-wrap: unset;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#t3_bowling_player_data_bg {
  background-image: linear-gradient(
    180deg,
    #ff9469 0%,
    #e4602b 48.96%,
    #d83e22 100%
  );
  height: 5.3vh;
  width: 42.8vw;
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  font-size: 2.9vh;
  font-weight: 500;
  line-height: 3.3vh;
  color: white;
}
#t3_bowling_overs {
  position: absolute;
  left: 22vw;
  width: 3.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#t3_bowling_dots {
  position: absolute;
  left: 29.7vw;
  width: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#t3_bowling_runs {
  position: absolute;
  left: 36.8vw;
  width: 2.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#t3_bowling_wickets {
  position: absolute;
  left: 44.1vw;
  width: 4.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#t3_bowling_economy {
  position: absolute;
  left: 52.8vw;
  width: 5.1vw;
  display: flex;
  align-items: top;
  justify-content: center;
}

#t3_bowling_fall_wickets_bg {
  width: 15.5vw;
  height: 5.3vh;
  margin-left: 5.2vw;
  position: absolute;
  top: 53.16vh;
  display: flex;
}
#t3_fall {
  position: absolute;
  top: 53.16vh;
}
#t3_bowling_fallen_wicket_bg {
  width: 58.3vw;
  height: 5.3vh;
  /* position: absolute; */
  margin-left: 5.2vw;
  display: flex;
}
#t3_bowling_fallen_wicket_text_bg {
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  width: 15.5vw;
  height: 5.3vh;
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
}
#t3_fallen_wicket_text {
  margin-left: 1.6vw;
  font-size: 2vh;
  line-height: 2.9vh;
  font-weight: 500 !important;
  color: white;
  text-transform: uppercase;
}
#t3_bowling_fallen_wicket_data_bg {
  /* background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%);
  height: 5.3vh;
  width: 42.8vw;
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  font-size: 2.9vh;
  font-weight: 500;
  line-height: 3.3vh;
  color: white;
  display: flex;
  gap: 3.1vw;
  color: black;
  padding-left: 2vw; */
  height: 5.3vh;
  width: 42.8vw;
  background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%);
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.3vh;
  color: #0b1234;
  display: grid !important;
  align-items: center;
  /* width: 37vw; */
  /* margin-top: 0.8vh; */
  padding-left: 1.5vw;
  text-align: center;
}
#t3_bowling_fallen_wicket_score_bg {
  /* background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%);
    height: 5.3vh;
    width: 42.8vw;
    border-bottom: 0.1vh solid white;
    display: flex;
    align-items: center;
    font-size: 2.9vh;
    font-weight: 500;
    line-height: 3.3vh;

    color: black;
    padding-left: 2vw; */
  height: 5.3vh;
  width: 42.8vw;
  background-image: linear-gradient(180deg, #fff 39.06%, #bcbcbc 100%);
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.3vh;
  /* margin-top: 12vh; */
  display: grid !important;
  /* width: 37vw; */
  padding-left: 1.5vw;
  color: black;
}
#t3_bowling_player_extras_bg {
  height: 5.3vh;
  width: 46vw;
  background-image: linear-gradient(
    180deg,
    #568cfe 0%,
    #2050bc 53.48%,
    #1e4796 100%
  );
  border-top: 0.1vh solid white;
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center;
  color: white;
  top: 66.4vh;
  position: absolute;
  left: 5.1vw;
}
#t3_bowling_overs_bg {
  height: 5.3vh;
  width: 12.4vw;
  background-image: linear-gradient(
    180deg,
    var(--orange_gradient_start_color) 0%,
    var(--orange_gradient_middle_color) 48.96%,
    var(--orange_gradient_end_color) 100%
  );
  border-bottom: 0.1vh solid white;
  display: flex;
  align-items: center !important;
  top: 66.4vh;
  position: absolute;
  left: 51.1vw;
  border-top: 0.1vh solid white;
}
#t3_bowling_extras {
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-left: 0vh;
}
#t3_bowling_bottom_overs {
  font-size: 2vh;
  font-weight: 500;
  line-height: 2.9vh;
  color: white;
  text-transform: uppercase;
  margin-left: 25.8vw;
}
#t3_bowling_runs_wickets {
  font-size: 2.9vh;
  font-weight: 600;
  line-height: 3.3vh;
  color: rgb(255, 255, 255);
  display: flex !important;
  text-align: center;
  background-position-x: center;
  align-items: center !important; 
  justify-content: center;
  width: 11.7vw;
  height: 3.3vh;
  /* background-color: #0b1234; */
}

* {
  user-select: none !important;
  font-family: "Inter", Arial, sans-serif;

}
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'); */

#t3_container_bg {
  /* position: relative; */
  /* font-family: "poppins", Arial, sans-serif; */
}
#t3_ticker_bg {
  background-image: linear-gradient(
    180deg,
    #eaeaea 0%,
    #fff 53.12%,
    #eaeaea 100%
  );
  height: 10.3vh;
  width: 100vw;
  position: absolute;
  top: 86.8vh;
  display: flex;
  /* align-items: center; */
}

#t3_teama_logo img {
  height: 7vh;
  width: 7vh;
  border-radius: 7vh;
  margin-left: 3.6vw;
  top: 1.6vh;
  position: absolute;
  border: 0.2vh solid transparent;
  /* border-image: linear-gradient(to bottom, #10629e, #1a4bb4, #2757ba, #10629e)
    10; */
  background-color: #10629e;
}
#t3_teama_name {
  margin-left: 8.6vw;
  position: absolute;
  /* top: 2.28vh; */
  font-size: 4.4vh;
  font-weight: 400;
  line-height: 5.3vh;
  display: flex;
  align-items: center;
  height: 10.3vh;
  color: #1a1717;
  text-transform: uppercase;
}
#t3_match_details {
  background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%),
    linear-gradient(180deg, #000 0%, #222 51.04%, #000 100%);
  height: 10.3vh;
  width: 16.25vw;
  margin-left: 18.2vw;
  position: absolute;
  display: flex;
  align-items: center;
}
#t3_team_runs_bg {
  color: white;
  margin-left: 1.14vw;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 13.75vw;
  height: 10.3vh;
}
#t3_teams_runs_wickets {
  font-size: 5.39vh;
  font-weight: 600;
  line-height: 6.9vh;
  display: flex;
  align-items: center;
  height: 10.3vh;
}
#t3_overs {
  font-size: 2.9vh;
  font-weight: 400;
  line-height: 3.2vh;
}
#t3_team_info_bg {
  background: #193b83;
  border-left: 0.1vh solid transparent;
  border-right: 0.1vh solid transparent;
  border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10;
  height: 10.3vh;
  width: 10.8vw;
  margin-left: 34.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2.9vh;
  font-weight: 400;
  line-height: 3.3vh;
  color: #fff;
  position: absolute;
}
#t3_partnership_data {
  background-color: #193b83;
  width: 48.04vw;
  height: 10.3vh;
  margin-left: 34.45vw;
  position: absolute;
  transition: 0.5s ease-in-out;
  /* border-left: 0.2vh solid transparent; */
  /* border-right: 0.2vh solid transparent; */
  border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Inter";
  font-size: 2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.9vh; /* 140% */
  text-transform: uppercase;
}
#t3_boundaries_data {
  background-color: #193b83;
  width: 47vw;
  height: 10.3vh;
  margin-left: 34.45vw;
  transition: 0.5s ease-in-out;
  /* border-left: 0.2vh solid transparent; */
  /* border-right: 0.2vh solid transparent; */
  border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Inter";
  font-size: 2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.9vh; /* 140% */
  text-transform: uppercase;
}
#partner_runs {
  color: #fff;
  font-family: "Inter";
  font-size: 2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.9vh;
  text-transform: uppercase;
}
@keyframes move-ending {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0px;
  }
}
#t3_target_runs {
  font-size: 2vh;
  font-weight: 600 !important;
  line-height: 3.3vh;
  /* background-color: #000; */
}
#t3_toss_team_name {
  /* font-size: 2vh; */
  /* line-height: 3.3vh; */
  font-weight: 500;
  text-transform: uppercase;
}
#t3_players {
  position: absolute;
  background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%);
  height: 10.3vh;
  width: 17vw;
  margin-left: 45.2vw;
  display: flex;
  /* padding-top: 1vh; */
  /* align-items: center; */
}
#t3_current_striker svg {
  width: 2.03vh;
  height: 2vh;
  margin-left: 0.5vw;
  margin-top: 1.8vh;
  position: absolute; /* transform: rotate(-135.223deg); */
  flex-shrink: 0;
}
#t3_striker {
  position: absolute;
  top: 1.3vh;
  left: 1.6vw;
  font-size: 2.4vh;
  font-weight: 450;
  line-height: 3.3vh;
  color: white;
  text-transform: capitalize;
}
#t3_striker_runs {
  text-align: left;
  position: absolute;
  right: 1.5vw;
  display: flex;
  align-items: right;

}
#t3_striker_runs_balls {
  position: absolute;
  left: 10.8vw;
  width: 3.4vw;
  display: flex;
  top: 0vh;
  gap: 0.5vh;
  align-items: baseline;

}
#t3_striker_balls {
  color: #fff;
  text-align: right;
  font-family: "Inter";
  font-size: 1.7vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5vh; /* 150% */
  position: absolute;
  /* right: 0; */
  /* right: -2.5vh; */
  left: 5vh;
  display: flex;
  /* align-items: center; */
  justify-content: left;
  margin-top: 0.5vh;
}
#t3_non_striker {
  position: absolute;
  top: 5.5vh;
  font-size: 2.4vh;
  font-weight: 450;
  line-height: 3.3vh;
  color: white;
  left: 1.6vw;
  height: 3.3vh;
  display: flex;
  text-transform: capitalize;
}
#t3_need_runs_info {
  background: #193b83;
  border-left: 0.1vh solid transparent;
  border-right: 0.1vh solid transparent;
  /* border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10; */
  height: 10.3vh;
  width: 10.8vw;
  margin-left: 34.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2vh;
  font-weight: 400;
  line-height: 3.3vh;
  color: #fff;
  position: absolute;
}
#t3_non_striker_runs {
  left: 10vw;
  position: absolute;
  width: 3.4vw;
  /* background-color: #000; */
  display: flex;
  align-items: center;
  justify-content: right;
  top: 0vh;
  font-weight: 500;
}
#t3_current_non_striker svg {
  width: 2.03vh;
  height: 2vh;
  /* transform: rotate(-135.223deg); */
  flex-shrink: 0;
  margin-left: 0.5vw;
  margin-top: 5.8vh;
  position: absolute;
}
#t3_bowler_data {
  position: absolute;
  left: 62.2vw;
  background-image: linear-gradient(180deg, #e4602b 0%, #d83e22 100%);
  height: 10.3vh;
  width: 19.3vw;
  display: flex;
  /* align-items: center; */
}

#t3_bowler_details {
  margin-left: 1.04vw;
  color: white;
  font-size: 2.4vh;
  font-weight: 450;
  line-height: 3.3vh;
  margin-top: 1.2vh;
  text-transform: capitalize;
  position: absolute;
}
#t3_bowler_runs_wickets {
  margin-left: 12.5vw;
  color: #fff;
  text-align: right;
  font-family: "Inter";
  font-size: 2.5vh;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  line-height: 3.3vh; /* 133.333% */
  margin-top: 1.2vh;
  right: 2vh;
}
#t3_bowler_overs {
  font-weight: 400 !important;
  color: #fff;
  text-align: right;
  font-family: "Inter";
  font-size: 1.7vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5vh; /* 150% */
}

#t3_teamb_logo img {
  height: 7vh;
  width: 7vh;
  border-radius: 50% !important;
  margin-left: 92.5vw;
  top: 1.6vh;
  position: absolute;
  border: 0.2vh solid transparent;
  /* border-image: linear-gradient(to bottom, #10629e, #1a4bb4, #2757ba, #10629e)
    10; */
  background-color: #000;
}
#t3_curr_overs {
  margin-top: 5.3vh;
  margin-left: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  overflow-x: scroll;
  /* white-space: nowrap; */
  /* width: 34vh; */
}
#t3_single_balls {
  height: 3.7vh;
  width: 3.7vh;
  background-color: #93321c;
  /* border-radius: 3vh; */
  border-radius: 3.7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  margin-left: 0.8vh;
}
#t3_extra_balls {
  height: 3.7vh;
  width: 3.7vh;
  background-color: #93321c;

  border-radius: 3.7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vh;
  font-weight: 450;
  line-height: 2.9vh;
  color: white;
  margin-left: 0.8vh;
}
#t3_extra_balls span {
  font-size: 1.3vh;
  line-height: 1.6vh;
  width: 4.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#t3_single_balls_six {
  height: 3.7vh;
  width: 3.7vh;
  background-color: white;
  border-radius: 3.7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
  font-weight: 450;
  /* line-height: 2.9vh; */
  color: #93321c;
  /* box-shadow: 0vh 0vh 0.83vh 0.4vh #fab09b inset; */
  margin-left: 0.8vh;
}
 #t3_teamb_name {
    /* margin-left: 86vw; */
    right: 9vw;
    position: absolute;
    /* top: 2.28vh; */
    font-size: 4.4vh;
    height: 10.3vh;
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 5.3vh;
    color: #1a1717;
    text-transform: uppercase;
    text-align:right;
    direction: rtl;
  }
.box{
  -webkit-animation: box 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: box 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes box {
  0% {
    -webkit-transform: translateX(-2vh);
    transform: translateX(-2vh);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes box {
  0% {
    -webkit-transform: translateX(-2vh);
    transform: translateX(-2vh);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.box1 {
  -webkit-animation: box1 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: box1 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes box1 {
  0% {
    -webkit-transform: translateX(-2vh);
    transform: translateX(-2vh);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes box1 {
  0% {
    -webkit-transform: translateX(-2vh);
    transform: translateX(-2vh);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* #boundaries_bg {
  border-right: 1px solid rgba(255, 255, 255, 0);
  border-left: 1px solid rgba(255, 255, 255, 0);
  background: #193b83;
  height: 100px;
} */
@media screen and (min-width: 500px) and (max-width: 997px) {
  #t3_bowler_runs_wickets {
    margin-left: 10.5vw;
    color: #fff;
    text-align: right;
    font-family: "Inter";
    font-size: 2.5vh;
    font-style: normal;
    font-weight: 500;
    position: absolute;
    line-height: 3.3vh; /* 133.333% */
    margin-top: 1.2vh;
    right: 2vh;
    background-position: center;
  }
  #t3_bowler_data {
    position: absolute;
    left: 62.2vw;
    background-image: linear-gradient(180deg, #e4602b 0%, #d83e22 100%);
    height: 10.3vh;
    width: 20.3vw;
    display: flex;
    /* align-items: center; */
  }
  #t3_curr_overs {
    margin-top: 5.3vh;
    margin-left: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    overflow-x: scroll;
    /* white-space: nowrap; */
    /* width: 34vh; */
  }
  #t3_single_balls {
    height: 14px;
    width: 14px;
    background-color: #93321c;
    /* border-radius: 3vh; */
    border-radius: 14px;
    display: flex;
    justify-content: left;
    background-position: center;
    align-items: center;
    font-size: 8px;
    font-weight: 500;
    line-height: 14px;
    color: white;
    margin-left: 0.8vh;
    /* padding-top: 0.5vh; */
  }
  #t3_extra_balls {
    /* height: 14px; */
    width: 14px;
    background-color: #93321c;
    /* border-radius: 3vh; */
    border-radius: 14px;
    display: flex;
    background-position: center;

    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 450;
    /* line-height: 16px; */
    color: white;
    margin-left: 0.8vh;
    /* line-height: 16px; */
    /* padding-top: 1vh; */
  }
  #t3_extra_balls span {
    align-items: center;
    /* font-size: 8px !important; */
    /* line-height: 8px !important; */
    font-weight: 450 !important;
    /* line-height: 10px !important; */
    color: white;
    display: flex;
    background-position: center;
    justify-content: center;
    align-items: center;
    /* height: 3.7vh !important; */
    /* width: 4.5vh !important; */
  }
  #t3_single_balls_six {
    height: 14px;
    width: 14px;
    background-color: #ffffff;
    /* border-radius: 3vh; */
    border-radius: 14px;
    display: flex;
    justify-content: center;
    background-position: center;
    align-items: center;
    font-size: 8px;
    font-weight: 500;
    /* line-height: 16px; */
    color: #93321c;
    margin-left: 0.8vh;
  }
 
  #t3_teamb_logo img {
    height: 7vh;
    width: 7vh;
    border-radius: 7vh !important;
    margin-left: 93vw;
    top: 1.6vh;
    position: absolute;
    border: 0.2vh solid transparent;
    /* border-image: linear-gradient(to bottom, #10629e, #1a4bb4, #2757ba, #10629e)
      10; */
    background-color: #000;
  }
}
@media screen and (min-width: 997px) and (max-width: 1390px) {
  #t3_teama_logo img {
    height: 7vh;
    width: 7vh;
    border-radius: 7vh;
    margin-left: 2.5vw;
    top: 1.6vh;
    position: absolute;
    border: 0.2vh solid transparent;
    /* border-image: linear-gradient(to bottom, #10629e, #1a4bb4, #2757ba, #10629e)
      10; */
    background-color: #10629e;
  }
  #t3_match_details {
    background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%),
      linear-gradient(180deg, #000 0%, #222 51.04%, #000 100%);
    height: 10.3vh;
    width: 16.25vw;
    margin-left: 18.5vw;
    position: absolute;
    display: flex;
    align-items: center;
  }
  #t3_team_runs_bg {
    color: white;
    margin-left: 1.14vw;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 13.75vw;
    height: 10.3vh;
    gap: 0.5vh;
  }
  #t3_teams_runs_wickets {
    font-size: 3.5vh;
    font-weight: 600;
    line-height: 5.9vh;
    display: flex;
    align-items: center;
    gap: 1vh;
    height: 10.3vh;
  }
  #t3_overs {
    font-size: 1.7vh;
    font-weight: 500;
    line-height: 3.2vh;
  }
  #t3_need_runs_info {
    background: #193b83;
    border-left: 0.1vh solid transparent;
    border-right: 0.1vh solid transparent;
    /* border-image: linear-gradient(to bottom, #ffffff00, #ffffff, #ffffff00) 10; */
    height: 10.3vh;
    width: 10.8vw;
    margin-left: 32.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.5vh;
    font-weight: 400;
    line-height: 3.3vh;
    color: #fff;
    position: absolute;
  }
  #t3_players {
    position: absolute;
    background-image: linear-gradient(180deg, #2050bc 0%, #1e4796 100%);
    height: 10.3vh;
    width: 18vw;
    margin-left: 43.2vw;
    display: flex;
    /* padding-top: 1vh; */
    /* align-items: center; */
  }
  #t3_striker {
    position: absolute;
    top: 1.3vh;
    left: 1.6vw;
    font-size: 2vh;
    font-weight: 450;
    line-height: 3.3vh;
    color: white;
    text-transform: capitalize;
  }
  #t3_striker_runs {
    text-align: left;
    position: absolute;
    right: 1.2vw;
    display: flex;
    align-items: right;
  
  }
  #t3_striker_runs_balls {
    position: absolute;
    left: 10.8vw;
    width: 3.4vw;
    display: flex;
    top: 0vh;
    gap: 0.5vh;
    align-items: baseline;
  
  }
  #t3_striker_balls {
    color: #fff;
    text-align: right;
    font-family: "Inter";
    font-size: 1.7vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5vh; /* 150% */
    position: absolute;
    /* right: 0; */
    /* right: -2.5vh; */
    left: 4vh;
    display: flex;
    /* align-items: center; */
    justify-content: left;
    margin-top: 0.5vh;
  }
  #t3_bowler_runs_wickets {
    margin-left: 10.5vw;
    color: #fff;
    text-align: right;
    font-family: "Inter";
    font-size: 2.5vh;
    font-style: normal;
    font-weight: 500;
    position: absolute;
    line-height: 3.3vh; /* 133.333% */
    margin-top: 1.2vh;
    right: 2vh;
    background-position: center;
  }
  #t3_bowler_data {
    position: absolute;
    left: 61.2vw;
    background-image: linear-gradient(180deg, #e4602b 0%, #d83e22 100%);
    height: 10.3vh;
    width: 21.3vw;
    display: flex;
    /* align-items: center; */
  }
  #t3_curr_overs {
    margin-top: 5.3vh;
    margin-left: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    overflow-x: scroll;
    /* white-space: nowrap; */
    /* width: 34vh; */
  }
  #t3_single_balls {
    height: 28px;
    width: 28px;
    background-color: #93321c;
    /* border-radius: 3vh; */
    border-radius: 28px;
    display: flex;
    justify-content: left;
    background-position: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: white;
    margin-left: 0.8vh;
    /* padding-top: 0.5vh; */
  }
  #t3_extra_balls {
    /* height: 28px; */
    width: 28px;
    background-color: #93321c;
    /* border-radius: 3vh; */
    border-radius: 28px;
    display: flex;
    background-position: center;

    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 450;
    /* line-height: 16px; */
    color: white;
    margin-left: 0.8vh;
    /* line-height: 16px; */
    /* padding-top: 1vh; */
  }
  #t3_extra_balls span {
    align-items: center;
    /* font-size: 18px !important; */
    /* line-height: 18px !important; */
    font-weight: 450 !important;
    /* line-height: 10px !important; */
    color: white;
    display: flex;
    background-position: center;
    justify-content: center;
    align-items: center;
    /* height: 3.7vh !important; */
    /* width: 4.5vh !important; */
  }
  #t3_single_balls_six {
    height: 28px;
    width: 28px;
    background-color: #ffffff;
    /* border-radius: 3vh; */
    border-radius: 28px;
    display: flex;
    justify-content: center;
    background-position: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    /* line-height: 16px; */
    color: #93321c;
    margin-left: 0.8vh;
  }
  #t3_teamb_name {
    /* margin-left: 86vw; */
    right: 8vw;
    position: absolute;
    /* top: 2.28vh; */
    font-size: 4.4vh;
    height: 10.3vh;
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 5.3vh;
    color: #1a1717;
    text-transform: uppercase;
    text-align:right;
    direction: rtl;
  }
  #t3_teamb_logo img {
    height: 7vh;
    width: 7vh;
    border-radius: 7vh !important;
    margin-left: 93vw;
    top: 1.6vh;
    position: absolute;
    border: 0.2vh solid transparent;
    /* border-image: linear-gradient(to bottom, #10629e, #1a4bb4, #2757ba, #10629e)
      10; */
    background-color: #000;
  }
}
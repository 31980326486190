#toss_update_gradient_bg {
  position: absolute;
  top: 25vh !important;
  left: 25vw;
  height: 10.56vh;
  width: 52vw;
  font-family: "Oswald";
}

#toss_update_bg {
  border-bottom: 5vw solid #ffffff;
  border-left: 1.5vw solid transparent;
  border-right: 1.5vw solid transparent;
  height: 0;
  width: 52vw;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  left: 24vw;
  top: 24vh;
  text-transform: uppercase;
}
#toss_update_text {
  font-size: 3vw;
  font-weight: 700;
  line-height: 7vh;
  /* left: 44vw; */
  top: 1.2vh !important;
  position: absolute;
  color: #001030;
  text-transform: uppercase;
}
#teama_toss_logo {
  position: absolute;
  left: 27vw;
  bottom: 35vh;
}
#teama_toss_logo {
  position: absolute;
  left: 27vw;
  bottom: 35vh;
}
#toss_teama_outer_gradient {
  width: 10.3vw;
  height: 20.6vh;
  transform: rotate(50deg);
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0));
  display: flex;
  align-items: center;
  position: absolute;
  top: -45vh;
  left: 15vw;
  justify-content: center;
}
#toss_teama_inner_gradient {
  width: 8vw;
  height: 15.6vh;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#toss_teama_logo {
  transform: rotate(-50deg);
  height: 7vh;
  width: 3.5vw;
  border-radius: 50% !important;
}
#toss_teamb_gradient_effect {
  position: absolute;
  left: 120px;
  bottom: 320px;
}
#toss_teamb_outer_gradient {
  width: 10.3vw;
  height: 20.6vh;
  transform: rotate(-50deg);
  left: -16vw;
  background-image: linear-gradient(#aef40a 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  position: absolute;
  top: -45vh;
  align-items: center;
  justify-content: center;
}
#toss_teamb_inner_gradient {
  width: 8vw;
  height: 15.6vh;
  position: absolute;
  background-image: linear-gradient(#73e707 0%, rgba(174, 244, 10, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#toss_teamb_logo {
  transform: rotate(50deg);
  height: 7vh;
  border-radius: 50% !important;
  width: 3.5vw;
}

#toss_green_layer {
  border-bottom: 3.75vw solid #bffe01;
  border-left: 2.08vw solid transparent;
  border-right: 2.08vw solid transparent;
  height: 0;
  width: 84.16vw;
  position: absolute;
  left: 7.86vw;
  bottom: 7.4vh;
}
#toss_teama_short_name {
  font-size: 1.9vw;
  line-height: 4.5vh;
  font-weight: 500;
  color: #001030;
  position: absolute;
  top: 1.4vh;
  left: 13.41vw;
  text-transform: uppercase;
}
#toss_teama_full_name {
  font-size: 1vw;
  font-weight: 400;
  line-height: 4.5vh;
  position: absolute;
  top: 1.4vh;
  left: 18vw;
  text-transform: uppercase;
}
#toss_vs_text_bg {
  width: 12.6vw;
  height: 9.1vh;
  transform: skewX(-25deg);
  background-color: #e50785;
  position: absolute;
  top: -2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#toss_vs_text {
  font-size: 2.9vw;
  line-height: 6.6vh;
  color: #ffffff;
  font-weight: 700;
  transform: skew(25deg);
  z-index: 2;
}
#toss_teamb_short_name {
  font-size: 1.9vw;
  line-height: 4.5vh;
  font-weight: 500;
  color: #001030;
  position: absolute;
  top: 1.4vh;
  left: 52.41vw;
  text-transform: uppercase;
}
#toss_teamb_full_name {
  font-size: 1.2vw;
  font-weight: 400;
  position: absolute;
  left: 57vw;
  font-size: 1vw;
  top: 1.4vh;
  line-height: 4.5vh;
  text-transform: uppercase;
}
#toss_blue_layer {
  height: 7.5vh;
  width: 100vw;
  background-color: #01102b;
  font-size: 1.25vw;
  line-height: 3.33vh;
  font-weight: 500;
  text-transform: uppercase;
}

#t2_bowling_font {
  font-family: "Inter";
}
#t2_bowling_title_bg {
  top: 3.9vh;
  position: absolute;
  left: 9.4vw;
  z-index: 1;
}
#t2_bowling_title_bg img {
  height: 14.5vh;
  width: 81.1vw;
}
#t2_bowling_teama_logo {
  position: absolute;
  top: 5.6vh;
  left: 14.8vw;
  z-index: 1;
}
#t2_bowling_teama_logo img {
  height: 3.5vw;
  width: 3.5vw;
  position: absolute;
  top: 1vh;
  border: 0.18vw solid #918171;
  border-radius: 3.5vw;
}
#t2_bowling_teama_short_name {
  position: absolute;
  top: 7vh;
  left: 19.6vw;
  font-size: 1.87vw;
  font-weight: 700;
  line-height: 4.5vh;
  text-transform: uppercase;
  color: #001030;
  z-index: 1;
}
#t2_bowling_teama_name {
  position: absolute;
  top: 11.5vh;
  left: 19.6vw;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2.9vh;
  text-transform: uppercase;
  z-index: 1;
  color: #001030;
}
#t2_bowling_playing_text {
  position: absolute;
  left: 43vw;
  top: 7.3vh;
  font-size: 1.6vw;
  font-weight: 800;
  line-height: 4.1vh;
  z-index: 1;
  text-transform: uppercase;
}
#t2_bowling_bowling_summary {
  position: absolute;
  left: 55.7vw;
  top: 8.1vh;
  font-size: 1.6vw;
  font-weight: 800;
  color: #001030;
  z-index: 1;
  text-transform: uppercase;
}
#t2_white_bowling_bg {
  position: absolute;
  left: 16vw;
  top: 10.4vh;
}
#t2_white_bowling_bg img {
  height: 77vh;
  width: 67vw;
  z-index: 0;
}
#t2_bowling_bottom_line {
  position: absolute;
  left: 13vw;
  top: 83.8vh;
  height: 6.25vh;
  width: 73.2vw;
  background-image: linear-gradient(
    180deg,
    #918171 0%,
    #ece5d7 45.98%,
    #f1e7d6 56.55%,
    #c4b6a4 92.47%
  );
  border-radius: 1.4vw;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  line-height: 2.8vh;
  color: #68696d;
  text-transform: uppercase;
}
#t2_bowling_row {
  position: absolute;
  left: 2.7vw;
  top: 8.9vh;
}

#t2_bowling_player_type_bg {
  /* position: absolute; */
  border-radius: 1.25vw;
  margin-top: 0.4vh;
  width: 62vw;
  height: 5.4vh;
  background-image: linear-gradient(
    to right,
    #97897b,
    #7b7166,
    #6e665a,
    #60574e,
    #504a42,
    #2f2b26
  );
  display: flex;
  align-items: center;
}

#t2_bowling_extras {
  position: absolute;
  left: 8vw;
}
#t2_bowling_overs {
  position: absolute;
  left: 39.5vw;
}
#t2_bowling_extras_overs_bg {
  left: 13vw;
  top: 83.8vh;
  height: 6.25vh;
  width: 55.7vw;
  background-image: linear-gradient(180deg, #fff 0%, #d6d6d6 100%);
  border-radius: 1.4vw;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  line-height: 2.8vh;
  color: #68696d;
  text-transform: uppercase;
}
#t2_bowling_result {
  position: absolute;
  left: 59.2vw;
  font-size: 1.8vw;
  font-weight: 800;
  line-height: 4.5vh;
  color: #003125;
}
#t2_bowling_topics {
  position: absolute;
  left: 29.4vw;
  width: 29.9vw;
  top: 1.9vh;
  font-size: 0.8vw;
  font-weight: 700;
  display: flex;
  gap: 3.5vw;
  line-height: 2.5vh;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}

#t2_bowling_player_bg {
  margin-top: 0.8vh;
  background-image: linear-gradient(180deg, #002513 0%, #003623 100%);
  height: 5.4vh;
  width: 62.2vw;
  border-radius: 1.4vw;
  font-size: 1.2vw;
  font-weight: 700;
  color: #ffffff;
}
#t2_bowling_player_overs {
  position: absolute;
  left: 29.5vw;
  margin-top: 0.5vh;
  width: 1.9vw;
}
#t2_bowling_player_dots {
  position: absolute;
  left: 36vw;
  margin-top: 0.5vh;
  width: 1.9vw;
}
#t2_bowling_player_wickets {
  position: absolute;
  left: 41.5vw;
  margin-top: 0.5vh;
  width: 1.9vw;
}
#t2_bowling_player_runs {
  position: absolute;
  left: 48.5vw;
  margin-top: 0.5vh;
  width: 1.9vw;
}
#t2_bowling_player_economy {
  position: absolute;
  left: 55vw;
  margin-top: 0.5vh;
  width: 1.9vw;
}
#t2_bowling_player_name_bg {
  position: absolute;
  width: 23.8vw;
  height: 5.4vh;
  background-image: linear-gradient(#ffffff, #d6d6d6);
  display: flex;
  align-items: center;
  padding-left: 2.5vw;
  border-radius: 1.25vw;
  font-size: 1vw;
  font-weight: 600;
  line-height: 2.9vh;
  color: #001030;
  text-transform: uppercase;
}
#t2_fall_wicket_scores_bg {
  position: absolute;
  top: 40vh;
}
#t2_bowling_player_wicket_fall_bg {
  margin-top: 10vh;
  background-image: linear-gradient(180deg, #002513 0%, #003623 100%);
  height: 5.4vh;
  width: 62.2vw;
  border-radius: 1.4vw;
  font-size: 1.25vw;
  font-weight: 700;
  color: #ffffff;
}
#t2_fall_wicket {
  position: absolute;
  display: flex;
  left: 26vw;
  margin-top: 0.8vh;
  gap: 2.5vw;
}
#t2_bowling_player_scores_bg {
  margin-top: 1vh;
  background-image: linear-gradient(180deg, #002513 0%, #003623 100%);
  height: 5.4vh;
  width: 62.2vw;
  border-radius: 1.4vw;
  font-size: 1.25vw;
  font-weight: 700;
  color: #ffffff;
}
#t2_scores {
  position: absolute;
  display: flex;
  left: 25.5vw;
  margin-top: 0.8vh;
  gap: 1.5vw;
}
